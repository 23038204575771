/**
 * Scroll of left menu on Environment section (Medioambiente)
 */
export class Environment {

    /** If is scrollable */
    scrollable= true

    /**
     *
     */
    constructor() {
        fama.utils.debug('Environment loaded')
        this.eventListeners()

        if($('.slider-enviroment').length > 0) {
            this.initEnvironmentSlider()
        }
    }

    /**
     *
     */
    eventListeners() {
        $(window).on('scroll', () => {
            if(this.scrollable) {
                $('.module-list-container >.sofas-medioambiente').each((i, item) => {
                    let $item = $(item)
                    let index=$item.data('index');
                    if ($(window).scrollTop() >= $item.position().top - 300) {
                        this.setActiveMenuEntry(index)
                    }
                });
            }
        });
    }

    initEnvironmentSlider() {
        $('.js-seeMoreEnviroment').on('click', e => {this.open(e)});
        $('.js-seeLessEnviroment').on('click', e => {this.close(e)});
        $('.js-seeMoreSustainability').on('click', e => {this.seeMoreSustainability(e)});
        $('.js-scrollSection').on('click', e => { this.scrollSection(e) });
    }

    /**
     * Scroll left menu
     * @param {*} e
     */
    scrollSection(e){
        let elem = $(e.currentTarget);
        let sectionID = elem.attr('data-index');
        let elemScroll = $(`.section-empresa[data-index="${sectionID}"]`);
        this.scrollable = false
        $('html, body').animate({
            scrollTop: elemScroll.offset().top - 102
        }, 600, 'swing',
        () => { //Avoid processing scroll while animates
            this.scrollable = true
        });
        this.setActiveMenuEntry(sectionID)
    }

    /**
     * Set left menu entry active
     * @param {*} index
     */
    setActiveMenuEntry(index) {
        if (!$(`.inner-mediaombiente[data-index='${index}']`).hasClass('isSelected')) {
            $('.inner-mediaombiente').removeClass('isSelected'); // eliminamos la clase active del que la tenga
            $(`.inner-mediaombiente[data-index="${index}"]`).addClass('isSelected');
        }
    }

    /**
     * @param {JQuery event} e
     */
    open(e){
        let $element = $(e.currentTarget)
        let $slider = $('.slider-enviroment');
        let text = $element.closest('.wrap-text').find('.text');
        let less = $element.data('text-less');
        text.removeClass('isClose').addClass('isOpen');

        $element.removeClass('js-seeMoreEnviroment');
        $element.addClass('js-seeLessEnviroment');
        $element.html(less);

        if($element.hasClass('js-seeLessEnviroment')){
            $element.on('click', e => {this.close(e)});
        }

        $slider.find('.slick-list').height('auto');
    }

    /**
     *
     * @param {JQuery event} e
     */
    close(e){
        let $element = $(e.currentTarget)
        let $slider = $('.slider-enviroment');
        let text = $element.closest('.wrap-text').find('.text');
        let more = $element.data('text-more');
        text.removeClass('isOpen').addClass('isClose');

        $element.removeClass('js-seeLessEnviroment');
        $element.addClass('js-seeMoreEnviroment');
        $element.html(more);
        if($element.hasClass('js-seeMoreEnviroment')){
            $element.on('click', e => {this.open(e)});
        }

        $slider.find('.slick-list').height('auto');
    }

    /**
     *
     * @param {*} slider
     * @deprecated
     */
    setHidden(slider){
        $('.slick-track .slick-slide:not(.slick-active)', slider).each(function(item) {
            // add current active slide height to our active slides array
            $(this).addClass('notSee');
            $(this).removeClass('see');

        });
    }

    /**
     *
     * @param {*} slider
     * @deprecated
     */
    multiSlideAdaptiveHeight(slider) {
        // set our vars
        let activeSlides = [];
        let tallestSlide = 0;

        // very short delay in order for us get the correct active slides
        setTimeout(function() {

            // loop through each active slide for our current slider
            $('.slick-track .slick-active', slider).each(function(item) {

                // add current active slide height to our active slides array
                activeSlides[item] = $(this).outerHeight();

            });


            // for each of the active slides heights
            activeSlides.forEach(function(item) {

                // if current active slide height is greater than tallest slide height
                if (item > tallestSlide) {

                    // override tallest slide height to current active slide height
                    tallestSlide = item;

                }

            });

            // set the current slider slick list height to current active tallest slide height
            $('.slider-ourTours.slick-vertical .slick-list').height(tallestSlide);
            // $('.slider-ourTours.slick-vertical .slick-list')[0].style.setProperty('height', tallestSlide, 'important');
            $(".slider-ourTours.slick-vertical .slick-list").css("cssText", "height:"+tallestSlide+"px !important");
        }, 10);

    }
    // (?<=[^\<\>\!])(?>=\S)?=(?=\S)
    /**
     *
     * @param {*} e
     */
    seeMoreSustainability(e){
        let btn=$(e.currentTarget);
        let numPorPage = 10;
        let currentItem = btn.closest('.wrap-content').find('.wrap-item.visible:last');
        var i;

        let numCurrentItem = currentItem.data('current');
        let total = currentItem.data('total');
        if((numCurrentItem+numPorPage)<=total){
            for( i = numCurrentItem; i<=numCurrentItem+numPorPage; i++){
                btn.closest('.wrap-content').find('.wrap-item.hidden[data-current="'+i+'"]').removeClass('hidden').addClass('visible');
                if(i===total){
                    btn.hide();
                }
            }
        }else{
            for( i=numCurrentItem; i<=total; i++){
                btn.closest('.wrap-content').find('.wrap-item.hidden[data-current="'+i+'"]').removeClass('hidden').addClass('visible');
                if(i===total){
                    btn.hide();
                }
            }
        }
    }
}
