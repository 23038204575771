/**
 * @name VideoManager
 * @author - LoP
 * @refactor - Ibsen
 * @version 1.0
 */

//OnlyFama
export class VideoManager {

    static $videoList = $('.js-videosList')

    vimeoParams             = {
        preview : '?api=1&background=0&autoplay=false&muted=1&loop=0&controls=0',
        play: '?api=1&background=0&autoplay=false&muted=0&loop=0&controls=1'
    }
	$iframeAuxContainer     = $('.js-iframeAuxContainer') // Aux iframe to swap between detail and list player
    $popupVideoContainer    = $('.js-videoDetail').find('.video-detail-video-container')
    autoplay                = true
	delayToLoadAuxIframe    = 500 // 0.5 second
	timerToLoadAuxIframe    = null
	videoToOpen             = null
    currentVideoPlayer      = null

    /**
     * @constructor
     */
    constructor() {
        fama.utils.debug('VideoManager loaded')
        this.bindEvents()
        this.setOrderVideos()
        this.openStartVideo()
    }


    //____________________________________________________________
    //
    //  METHODS
    //_____________________________________________________________

    log(txt) {
        fama.utils.debug(txt)
    }

    /**
     * Bind all events
     */
    bindEvents(){
        $('.js-videosWrapper .videos-section-item .js-videoOpen').on('mouseenter',  (e) => {this.onStartPreview(e)});
		$('.js-videosWrapper .videos-section-item .js-videoOpen').on('mouseleave', (e) => {this.onStopPreview(e)});

        $('.js-videoOpen').on('click', (e) => {this.onTryToOpenDetail(e)});
        $('.js-videoDetail').on('click', '.js-videoClose', (e) => {this.onCloseDetail(e)});
        $('.js-videoDetail').on('click', '.js-videoDdescriptionMore', (e) => {this.onToggleDescription(e)});
        $('.js-videoDlikes').on('click', (e) => {this.onLikeVideo(e)});
        $('.js-videoDetail').find('.video-detail-body').on('scroll', (e) => {this.onMoveIframeAux(e)});

		$('#autoplay').on('change', (e) => {this.onChangeAutoplay(e)});

		let $searchVideo = $('.app-list.app-list_2021 #search-video');

		$searchVideo.on('keypress', e => {
            if(e.key === "Enter"){
                this.search($(e.currentTarget).val());
            }else{
                this.search($(e.currentTarget).val() + String.fromCharCode(e.which));
            }
		});

		$searchVideo.on('keyup', e => {
			if(e.key === "Backspace" || e.key === "Delete"){
				this.search($(e.currentTarget).val());
			}
		});
    }

    openStartVideo(){
        let url = $(location).attr('href');
        if(url.includes('#')) {
            $('#loaderVideo').removeClass('hiddenVideo');
            let index = $(location).attr('href').indexOf('@');
            let urlId = $(location).attr('href').substring(index+1, index+4);
            let id = '#video-'+urlId;
            let $myVideo = $(id);
            let videoID = $myVideo.attr('data-video-id');
            if (this.$iframeAuxContainer.find('[data-id="'+videoID+'"]').length === 0) {
                let $iframeAux = $('.js-iframeAuxItem').find('.iframeAux').clone();
                let videoOrder = $myVideo.attr('data-video-order');
                let videoSrc = $myVideo.attr('data-src')+this.vimeoParams.play;
                let videoWidth = $myVideo.width();
                let videoHeight = $myVideo.height();
                let videoBorderRadius = $myVideo.css('border-radius');
                let videoOffset = $myVideo.offset();
                let videoTop = videoOffset.top + (videoHeight / 2) - document.documentElement.scrollTop;
                let videoLeft = videoOffset.left + (videoWidth / 2);
                let requested = '1';
                let videoTitle = $myVideo.attr('data-video-title');
                let $newVideoContainer = $(this.$popupVideoContainer);
                let newVideoWidth = this.isMobile() ? $('body').width() : $newVideoContainer.width();
                let newVideoHeight = newVideoWidth * videoHeight / videoWidth;
                let scale = videoWidth / newVideoWidth;
                let borderRadius = this.isMobile() ? 0 : 25;
                $iframeAux.css({
                    'transform': 'scale('+scale+') translate(-50%, -50%)',
                    'width': newVideoWidth,
                    'height': newVideoHeight,
                    'borderRadius': borderRadius,
                    'top': videoTop,
                    'left': videoLeft
                });

                // Load event
                $iframeAux.on('load', () => {
                    this.videoToOpen = videoID;
                    this.videoToOpen = urlId;
                    // this.readyToOpenDetail();//
                    if (this.videoToOpen == this.videoToOpen) {
                        let videoID = this.videoToOpen;
                        let $myAuxIframe = $($(this.$iframeAuxContainer).find('[data-id="'+videoID+'"]'));
                        let videoHeight = $myAuxIframe.height();
                        let videoWidth = $myAuxIframe.width();
                        let videoOrder = $myAuxIframe.attr('data-order');
                        let $newVideoContainer = $(this.$popupVideoContainer);
                        let newVideoWidth = this.isMobile() ? $('body').width() : $newVideoContainer.width();
                        let newVideoHeight = newVideoWidth * videoHeight / videoWidth;
                        let newVideoOffset = $newVideoContainer.offset();
                        let newVideoTop = this.isMobile() ? ($('.video-detail-header').height() + (newVideoHeight / 2) + 1) : (newVideoOffset.top + (newVideoHeight / 2) - document.documentElement.scrollTop);
                        let newVideoLeft = (newVideoOffset.left + (newVideoWidth / 2));

                        $myAuxIframe.css({
                            'transform': 'scale(1) translate(-50%, -50%)',
                            'top': newVideoTop,
                            'left': newVideoLeft
                        });

                        // If in mobile version adapt detail body section to the remaining height of the device
                        if (this.isMobile()) {
                            let headerHeight = $('.video-detail-header').outerHeight();
                            //let screnHeight = screen.height;
                            let aux = headerHeight + newVideoHeight;
                            let bodyHeight = 'calc(100vh - '+aux+'px)';

                            $('.video-detail-body').css({
                                'height': bodyHeight,
                                'minHeight': bodyHeight,
                            });

                            // Scroll to top of the detail body
                            $('.video-detail-body').scrollTop($('.video-detail-body')[0].scrollHeight * -1);
                        }

                        let videoAux = new Vimeo.Player($myAuxIframe);

                        //$myAuxIframe.trigger('click');
                        $myAuxIframe.addClass('isVisible');
                        //videoAux.play(); // Autoplay

                        // Get detail info about the video (ajax call)
                        this.getVideoDetail(videoID, videoOrder);

                        // Set the height of the big video container according to the ratio of the video
                        $newVideoContainer.css({
                            'height': newVideoHeight,
                            'minHeight': newVideoHeight,
                        });

                        // Disable page scroll
                        $('body').css('overflow-y', 'hidden');

                        $('.js-videosList').addClass('showingDetail');
                        $('.js-videoDetail').addClass('isVisible');

                        // Remove all other aux iframes
                        let restAuxIframes = [];
                        $(this.$iframeAuxContainer).find('.js-iframeAux').each((i, iframe) => {
                            if ($(iframe).attr('data-id') != videoID) {
                                restAuxIframes.push(iframe);
                            }
                        });
                        $(restAuxIframes).removeClass('isVisible');
                        let urlOpen = $(location).attr('href').split('#')
                        $('.js-addShareUrl').append(urlOpen[0]+'#'+videoTitle.replace(/ /g,'_')+'@'+videoID);

                        setTimeout(() => {
                            $(restAuxIframes).remove();
                        }, 200);
                    }
                });

                $iframeAux.attr('data-id', videoID);
                $iframeAux.attr('data-order', videoOrder);
                $iframeAux.attr('src', videoSrc);
                $iframeAux.attr('data-requested', requested);
                $iframeAux.attr('data-video-title', videoTitle);

                // Add aux iframe to list of aux iframes
                this.$iframeAuxContainer.append($iframeAux);

                let videoPlayer = new Vimeo.Player($iframeAux);
                // End of video event
                videoPlayer.on('ended', function () {
                    // Play  next video if autoplay is enabled
                    if (this.autoplay && !this.isMobile()) {
                        $('.js-videoDetail').find('.video-next-container.js-videoOpen').trigger('click');
                    }
                });
            }
        }
    }

    /**
     * Set video order
     */
    setOrderVideos() {
		$('.js-videosWrapper').find('iframe').each((index, iframe) => {
			$(iframe).attr('data-video-order', index);
		});
	}

    /**
     *
     * @param {*} $list
     */
    loadAllIframes($list) {
        // If we are in desktop load iframes in the background
        if (!(this.isMobile())) {
            $list.find('iframe').each((i, iframe) => {
                let $iframe = $(iframe)
                let iframeSRC = $iframe.attr('data-src')+this.vimeoParams.play;
                $iframe.attr('src', iframeSRC);
                $iframe.on('load', this.afterPlayerLoaded($iframe));
            });
        }
    }

    /**
     * Set src attribute to iframe
     * @param {jqueryElement} $list
     */
    loadIframeSrc($iframe) {
        let p = new Promise((resolve, reject) => {
            // If we are in desktop load iframes in the background
            if(!$iframe.attr('src') || $iframe.attr('src') == "") {
                let iframeSRC = $iframe.attr('data-src') + this.vimeoParams.preview;
                $iframe.attr('src', iframeSRC);
            }

            resolve($iframe)
        })
        return p
    }


    /**
     * Create a new player on a valid iframe
     * @param {jqueryElement} $element
     * @returns
     */
    createNewVideoPlayer($element) {
        if (this.currentVideoPlayer) {
            // should unlisten events before unload
            this.currentVideoPlayer.off('ended')
            this.currentVideoPlayer.unload()
        }

        try {
            this.currentVideoPlayer = new Vimeo.Player($element)
            return this.currentVideoPlayer;
        } catch (error) {
            console.error("Something happened creating a new Vimeo Player", error)
        }
    }

    /**
     * Load iframe in popup container to show the video
     * @param {jqueryElement} $selectedIframeVideo
     * @param {boolean} isRequested
     */
    loadAuxIframe($selectedIframeVideo, isRequested = false) {
    	let videoID = $selectedIframeVideo.attr('data-video-id');
		// Check if the video resquested to load is not already loaded
		if (this.$iframeAuxContainer.find('[data-id="'+videoID+'"]').length === 0) {
            fama.utils.debug('test')
			let popupIframe = $('.js-iframeAuxItem').find('.iframeAux').clone();

			let videoOrder = $selectedIframeVideo.attr('data-video-order');
			let videoSrc = $selectedIframeVideo.attr('data-src')+this.vimeoParams.play;
			let videoWidth = $selectedIframeVideo.width();
			let videoHeight = $selectedIframeVideo.height();
			let videoBorderRadius = $selectedIframeVideo.css('border-radius');
			let videoOffset = $selectedIframeVideo.offset();
			let videoTop = videoOffset.top + (videoHeight / 2) - document.documentElement.scrollTop;
			let videoLeft = videoOffset.left + (videoWidth / 2);
			let requested = isRequested ? '1' : '0';
			let $newVideoContainer = $(this.$popupVideoContainer);
			let newVideoWidth = this.isMobile() ? $('body').width() : $newVideoContainer.width();
			let newVideoHeight = newVideoWidth * videoHeight / videoWidth;
			let scale = videoWidth / newVideoWidth;
			let borderRadius = this.isMobile() ? 0 : 25;
			let videoTitle = $selectedIframeVideo.attr('data-video-title');

            popupIframe.css({
			    'transform': 'scale('+scale+') translate(-50%, -50%)',
			    'width': newVideoWidth,
			    'height': newVideoHeight,
			 	'borderRadius': borderRadius,
			 	'top': videoTop,
                'left': videoLeft
			});

            /*
            popupIframe.css({

               'width': '95%',
               'height': '50%',
                'top': '50%',
                'left': '50%',
                'transform': 'translateX(-50%) translateY(-50%)',
                'max-width': newVideoWidth,
                'max-height': newVideoHeight
            });
            */

			// // Load event
			popupIframe.on('load',  () => {
				this.videoToOpen = videoID;
			});

            // Setting atributes
			popupIframe.attr('data-id', videoID);
			popupIframe.attr('data-order', videoOrder);
			popupIframe.attr('src', videoSrc);
			popupIframe.attr('data-requested', requested);
            popupIframe.attr('data-video-title', videoTitle);

            // Add aux iframe to list of aux iframes
			this.$iframeAuxContainer.append(popupIframe);
		}
	}

    /**
     *
     * @param {number} videoToOpen video ID to be opened
     * @param {jqueryElement} $iframeVideo selectd iframe from video list
     */
    readyToOpenDetail(videoToOpen,$iframePopupVideo) {
        // Open video detail only when a video is clicked AND its aux iframe is already loaded
        if (videoToOpen) {
            let videoID = videoToOpen;
            let videoHeight = $iframePopupVideo.height();
            let videoWidth = $iframePopupVideo.width();
            let videoOrder = $iframePopupVideo.attr('data-order');
            let $newVideoContainer = this.$popupVideoContainer;
            let newVideoWidth = this.isMobile() ? $('body').width() : $newVideoContainer.width();
            let newVideoHeight = newVideoWidth * videoHeight / videoWidth;
            let newVideoOffset = $newVideoContainer.offset();
            let newVideoTop = this.isMobile()
                ? ($('.video-detail-header').height() + (newVideoHeight / 2) + 1)
                : (newVideoOffset.top + (newVideoHeight / 2) - document.documentElement.scrollTop);
            let newVideoLeft = (newVideoOffset.left + (newVideoWidth / 2));
            let videoTitle = $iframePopupVideo.attr('data-video-title');
            let urlOpen = $(location).attr('href').split('#');

            // Setting URL
            history.pushState(null, "", urlOpen[0]+'#'+videoTitle.replace(/ /g,'_')+'@'+videoID);

            // Move iframe to final position
            $iframePopupVideo.css({
                'transform': 'scale(1) translate(-50%, -50%)',
                'top': newVideoTop,
                'left': newVideoLeft
            });

            // If in mobile version adapt detail body section to the remaining height of the device
            if (this.isMobile()) {
                let headerHeight = $('.video-detail-header').outerHeight();
                let aux = headerHeight + newVideoHeight;
                let bodyHeight = 'calc(100vh - '+aux+'px)';

                $('.video-detail-body').css({
                    'height': bodyHeight,
                    'minHeight': bodyHeight,
                });

                // Scroll to top of the detail body
                $('.video-detail-body').scrollTop($('.video-detail-body')[0].scrollHeight * -1);
            }

            $iframePopupVideo.addClass('isVisible');

            // Get detail info about the video (ajax call)
            this.getVideoDetail(videoID, videoOrder);

            // Set the height of the big video container according to the ratio of the video
            $newVideoContainer.css({
                'height': newVideoHeight,
                'minHeight': newVideoHeight,
            });

            // Disable page scroll
            $('body').css('overflow-y', 'hidden');

            $('.js-videosList').addClass('showingDetail');
            $('.js-videoDetail').addClass('isVisible');


            // Create Player and play
            let currTime = 0;
            if (this.currentVideoPlayer) {
                this.currentVideoPlayer.getCurrentTime().then((s) => {
                    currTime = s
                })
            }
            let vp = this.createNewVideoPlayer($iframePopupVideo)
			vp.on('ended',  () => {
				// Play  next video if autoplay is enabled
				if (this.autoplay ) {
					$('.js-videoDetail').find('.video-next-container.js-videoOpen').trigger('click');
				}
			});

            vp.ready().then(() => {
                vp.setMuted(false)
                vp.play();

                // TODO: It has to ignore current time when the load is coming from NEXT or PREVIOUS buttons
                // vp.setCurrentTime(currTime)
            })

            // Remove all other aux iframes
            let otherAuxIframes = [];
            $(this.$iframeAuxContainer).find('.js-iframeAux').each((i, iframe) => {
                if ($(iframe).attr('data-id') != videoID) {
                    otherAuxIframes.push(iframe);
                }
            });
            $(otherAuxIframes).removeClass('isVisible');
            setTimeout(() => {
                $(otherAuxIframes).remove();
            }, 200);
        }
    }

    /**
     * Search function
     * @param {*} search
     */
    search(search){
        console.log('search video');
		let $videosSection = $('.videos-section');
        $videosSection.removeClass('isVisible');
		search = search.toLocaleLowerCase();
        $('.videos-wrapper .no-result').hide();

		if(search.length >= 3){
			$videosSection.each((i,section) => {
                let $section = $(section);
				let items = $section.find('.videos-section-item');
				let text = $section.text().toLocaleLowerCase();

				if(text.search(search) > -1){
                    // If section not already visible, show
                    $section.addClass('isVisible');
					if(!$section.is(':visible')){
						$section.fadeIn();
					}

                    // Every element of section
					items.each((index, item) => {
                        let $item = $(item)
						let text = $item.text().toLocaleLowerCase(); //???
						if(text.search(search) > -1){
							if(!$item.is(':visible')){
								$item.fadeIn();
							}
						}
						else{
							$item.hide();
						}
					});
				}
				else{
					$section.hide();
				}
			});
            if($('.videos-section.isVisible').length < 1){
                $('.videos-wrapper .no-result').show();
            }
		}
		else{
			$videosSection.show();
			$videosSection.find('.videos-section-item').show();
		}
	}

    /**
     * Get video details
     * @param {number} videoID
     * @param {number} videoOrder
     */
    getVideoDetail(videoID, videoOrder) {
        let data = {
            id: videoID,
            lang: $('html').attr('lang'),
            user: $('.js-videoDetail').attr('data-user'),
            page: $('.js-videoDetail').attr('data-page')
        };

        $.ajax({
            type: 'POST',
            url: '/api/video-details',
            data: data
        })
        .done( (response) => {
            let videoDetail = response;
            let $videoDetail = $('.js-videoDetail');
            // Hide info elements before we request the info of the new video
            $videoDetail.removeClass('isLoaded');

            setTimeout(() => {
                this.populateDetail(videoDetail, videoOrder, videoID);
            }, 100);
        });
    }

    /**
     * Fill the detail fields
     * @param {object} detail
     * @param {number} order
     * @param {number} videoID
     */
    populateDetail(detail, order, videoID) {
        let $videoDetail = $('.js-videoDetail');
        let orderNum = parseInt(order);
        let $prevVideo = $('.js-videosList').find('[data-video-order="'+(orderNum-1)+'"]').length > 0 ? $($('.js-videosList').find('[data-video-order="'+(orderNum-1)+'"]').closest('.videos-section-item')) : null;
        let $nextVideo = $('.js-videosList').find('[data-video-order="'+(orderNum+1)+'"]').length > 0 ? $($('.js-videosList').find('[data-video-order="'+(orderNum+1)+'"]').closest('.videos-section-item')) : null;

        $videoDetail.find('.js-videoFamily').html(detail.family_group);
        $videoDetail.find('.js-videoDtitle').html(detail.title);
        $videoDetail.find('.js-videoDsubtitle').html(detail.subtitle);
        $videoDetail.find('.js-videoDlikes').attr('data-id', videoID);
        $videoDetail.find('.js-videoDlikes').html(detail.likes_num);
        $videoDetail.find('.js-videoDfamilyImg').attr('src', detail.family_img);
        $videoDetail.find('.js-videoDfamily').html(detail.family_name);
        $videoDetail.find('.js-videoDdescription').html(detail.description);
        $videoDetail.find('.video-info-description').removeClass('showAll');

        // Enable or not like button
        if (detail.is_liked) {
            $videoDetail.find('.js-videoDlikes').addClass('liked').prop('disabled', true);
        } else {
            $videoDetail.find('.js-videoDlikes').removeClass('liked').prop('disabled', false);
        }

        let $videoDetailPrev = $($videoDetail.find('.video-prev-container'));
        // Prev video
        if ($prevVideo) {
            let prevTitle = $prevVideo.find('.video-title').html();
            let prevIframe = $prevVideo.find('.video-container').find('iframe').attr('data-src');
            let prevImg = $prevVideo.find('.video-container').find('img').attr('src');
            let prevID = $prevVideo.find('iframe').attr('data-video-id');
            let $videoDetailPrevIframe = $videoDetailPrev.find('.video-container iframe');
            let title = $prevVideo.find('iframe').attr('data-video-title');
            $videoDetailPrev.find('.video-prev-next-title').html(prevTitle);
            $videoDetailPrev.find('.video-container img').attr('src', prevImg);
            $videoDetailPrevIframe.attr('data-src', prevIframe);
            $videoDetailPrevIframe.attr('src', prevIframe+ this.vimeoParams);
            $videoDetailPrevIframe.attr('data-video-id', prevID);
            $videoDetailPrevIframe.attr('data-video-order', (orderNum-1));
            $videoDetailPrevIframe.attr('data-video-title', title);
            $videoDetailPrev.show();
        } else {
            $videoDetailPrev.hide();
        }

        let $videoDetailNext = $($videoDetail.find('.video-next-container'));
        // Next video
        if ($nextVideo) {
            let nextTitle = $nextVideo.find('.video-title').html();;
            let nextIframe = $nextVideo.find('.video-container').find('iframe').attr('data-src');
            let nextImg = $nextVideo.find('.video-container').find('img').attr('src');
            let nextID = $nextVideo.find('iframe').attr('data-video-id');
            let $videoDetailNextIframe = $videoDetailNext.find('.video-container iframe');
            let title = $nextVideo.find('iframe').attr('data-video-title');

            $videoDetailNext.find('.video-prev-next-title').html(nextTitle);
            $videoDetailNext.find('.video-container img').attr('src', nextImg);
            $videoDetailNextIframe.attr('data-src', nextIframe);
            $videoDetailNextIframe.attr('src', nextIframe + this.vimeoParams);
            $videoDetailNextIframe.attr('data-video-id', nextID);
            $videoDetailNextIframe.attr('data-video-order', (orderNum+1));
            $videoDetailNextIframe.attr('data-video-title', title);
            $videoDetailNext.show();
        } else {
            $videoDetailNext.hide();
        }

        if (!$prevVideo && !$nextVideo) {
            $('.video-prev-next').hide();
        } else {
            $('.video-prev-next').show();
        }

        // Downloadable documents
        if (detail.docs.length > 0) {
            $videoDetail.find('.js-videoDdocs').show();
            $videoDetail.find('.js-videoDdocsList').html('');

            $(detail.docs).each((index, doc) => {
                let $doc = $($('.js-patternVideoDoc').find('.video-docs-item')).clone();
                $doc.find('.video-docs-item-link').attr('href', doc.link);
                $doc.find('.text').html(doc.name)
                if (doc.description && doc.description !== '') {
                    $doc.find('.video-docs-item-text').html(doc.description);
                }

                // Check if doc is a link or a file
                if (doc.is_document) {
                    $doc.find('.video-docs-item-link').attr('download', doc.name);
                } else {
                    $doc.find('.video-docs-item-link').attr('target', 'blank');
                }

                $videoDetail.find('.js-videoDdocsList').append($doc);
            });
        } else {
            $videoDetail.find('.js-videoDdocs').hide();
        }

        // Related videos
        if (detail.related.length > 0) {
            $videoDetail.find('.js-videoDrelated').show();
            $videoDetail.find('.js-videoDrelatedList').html('');

            $(detail.related).each((index, related) => {
                let $related = $($('.js-patternVideoRelated').find('.video-detail-related-item')).clone();
                let $relatedIframe = $related.find('.js-relatedIframe');
                $relatedIframe.attr('data-video-id', related.id);
                $relatedIframe.attr('data-video-title', related.title);
                $relatedIframe.attr('data-src', related.src);
                $relatedIframe.attr('src', related.src+this.vimeoParams);
                $relatedIframe.on('load', this.onNormalIframeLoaded);
                $related.find('.js-relatedImage').attr('src', related.img);
                $related.find('.js-relatedDuration').html(related.duration);
                $related.find('.js-relatedTitle').html(related.title);
                $related.find('.js-relatedFamilyName').html(related.family_name);
                $related.find('.js-relatedFamilyImage').attr('src', related.family_img);
                if (related.subtitle && related.subtitle !== '') {
                    $related.find('.js-relatedSubtitle').html(related.subtitle);
                }

                $videoDetail.find('.js-videoDrelatedList').append($related);
            });
        } else {
            $videoDetail.find('.js-videoDrelated').hide();
        }

        if (this.isMobile()) {
            // Scroll to top of the detail body
            $('.video-detail-body').scrollTop($('.video-detail-body')[0].scrollHeight * -1);
        }

        // Mark detail as loaded (proceed with animation in CSS)
        setTimeout(() => {
            $videoDetail.addClass('isLoaded');
        }, 100);
    }

    /**
     * Set isLoaded class to iframe when it is loaded
     */
    afterPlayerLoaded($iframe) {
		let $itemWrapper = $iframe.closest('.video-wrapper');
		$itemWrapper.addClass('isLoaded');
	}



    //____________________________________________________________
    //
    //  EVENTS
    //_____________________________________________________________

    /**
     * Start a preview on a selected element
     * @param {jqueryEvent} ev
     */
    onStartPreview(ev) {

        let $iframeSelectedVideo = $(ev.currentTarget).find('iframe');
        this.loadIframeSrc($iframeSelectedVideo).then(() => {
            this.createNewVideoPlayer($iframeSelectedVideo)
            this.currentVideoPlayer.ready().then(() => {
                this.afterPlayerLoaded($iframeSelectedVideo)
            });
            this.currentVideoPlayer.play();
        })

		// Load the aux iframe in case we want to see its detail after 0.5 second hovering the video
		// this.timerToLoadAuxIframe = setTimeout(() => {
		// 	this.loadAuxIframe($iframeSelectedVideo);
		// }, this.delayToLoadAuxIframe);
	}

    /**
     * Stop preview
     * @param {jqueryEvent} ev
     */
    onStopPreview(ev) {
		if (!this.isMobile()) {
			// let videoPlayer = new Vimeo.Player(ev);
            if(this.currentVideoPlayer){
                this.currentVideoPlayer.unload();
            }
		}

		// Remove aux iframe if its has not been requested to show its detail
		let $myVideo = $($(ev).find('iframe'));
		let videoID = $myVideo.attr('data-video-id');
		let $myAuxIframe = $(this.$iframeAuxContainer.find('[data-id="'+videoID+'"]'));

		if ($myAuxIframe.attr('data-requested') == '0') {
			this.videoToOpen = null;
			$myAuxIframe.remove();
		}

		clearTimeout(this.timerToLoadAuxIframe);
	}

    /**
     * Close detail
     */
    onCloseDetail() {
        $('#loaderVideo').addClass('hiddenVideo');
        let videoID = this.videoToOpen;
        let $iframeSelected = $(this.$iframeAuxContainer.find('[data-id="'+videoID+'"]'));
        let $videoDetail = $('.js-videoDetail');
        let urlOpen = $(location).attr('href').split('#');
        history.pushState(null, "", urlOpen[0]);
        // Enable page scroll
        $('body').css('overflow-y', 'auto');

        $iframeSelected.removeClass('isVisible');
        $videoDetail.removeClass('isVisible');

        $iframeSelected.off('load');
        setTimeout(() => {
            $iframeSelected.remove();
        }, 600);

        // Clear flags to open detail
        this.videoToOpen = null;

        if(this.currentVideoPlayer){
            this.currentVideoPlayer.unload()
            // this.videoToOpen = null;
        }

    }

    /**
     * Change autoplay option
     * @param {jqueryEvent} ev
     */
    onChangeAutoplay(ev) {
		this.autoplay = $(ev).is(':checked');
	}

    /**
     * When some video thumb is clicked
     * @param {jqueryEvent} ev
     */
    onTryToOpenDetail(ev) {
        let $iframeSelectedVideo = $(ev.currentTarget).find('iframe');
        let videoId = $iframeSelectedVideo.attr('data-video-id');
        let $popupIframe;

        // Load SRC on list video
        this.loadIframeSrc($iframeSelectedVideo).then(() => {
            // this.currentVideoPlayer = new Vimeo.Player($iframeSelectedVideo );
            // this.currentVideoPlayer.play();
            if (this.isMobile()) {

                // Scroll to top in popup
                $('.js-videoDetail').find('.video-detail-body').scrollTop(0);
            }

            if ($iframeSelectedVideo.is('iframe')) {
                this.loadAuxIframe($iframeSelectedVideo, true);
                $popupIframe = $(this.$iframeAuxContainer.find('[data-id="'+videoId+'"]'));
                $popupIframe.attr('data-requested', '1');
            }

            // TODO:
            this.videoToOpen = videoId;

            // if this.videoToOpen == vm.videoToOpen
            this.readyToOpenDetail(videoId, $popupIframe);
        })
    }

    /**
     * On like click
     */
    onLikeVideo() {
        let self = this;
        let data = {
            module: 'social',
            item_type: 2000,
            action: 'like',
            item_id: $(self).attr('data-id')
        };
        $.ajax({
            type: 'POST',
            url: '/ajax.php',
            data: data,
            beforesend() {
                $(self).prop('disabled', true);
            },
            success(response) {
                let likes_num = response.count;
                $(self).addClass('liked').prop('disabled', true);
                $(self).html(likes_num);
            },
            error() {
                $(self).prop('disabled', false);
            },
            complete() {

            }
        });
    }

    /**
     *
     */
    onToggleDescription() {
        $('.js-videoDetail').find('.video-info-description').toggleClass('showAll');
    }


    /**
     * called on scroll
     * @param {*} e
     */
    onMoveIframeAux(e) {
        if (!this.isMobile()) {
            let $auxIframe = $($iframeAuxContainer.find('.js-iframeAux'));
            let auxIframeTop = $auxIframe.css('top');
            let headerHeight = $('.js-videoDetail').find('.video-detail-header').outerHeight() * -1;
            let newTop = headerHeight - $(this).scrollTop();
            $auxIframe.css('marginTop', newTop);
        }
    }

    /**
     * Check if it is mobile
     * @returns {boolean}
     */
    isMobile() {
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()) || $('body').outerWidth() <= 768;
    }
}
