import { FamaUtils } from "../../classes/FamaUtils";
import { ToastManager } from "../../classes/ToastManager";
export class Warranty {
    utils = window.fama.utils ?? FamaUtils.getInstance()

    constructor() {
        this.init()
    }

    init() {
        $("#garantias_2021").each( (i,item) => {
        fama.utils.debug('Warranty init');
        let $item = $(item);
        // var id = $(this);
        //fama.utils.debug('select', id.find("select[id='pais_warranty']"));

        if (!this.utils.isLoad("select2", "$"))
        return;

        $item.find('.js-select2').select2({
            width: 'resolve',
        });


        $("#garantias_2021 button[type='submit']").off().on('click', (e) => {
            e.preventDefault();
            var form_ok = true;
            var $botton=$(e.currentTarget);

            // validation of select2
            if (!$.isNumeric($("#garantias_2021 select[id='pais_warranty']").val()) || $("#garantias_2021 select[id='pais_warranty']").val() <= 0) {
                // e.preventDefault();
                $("#garantias_2021 select[id='pais_warranty']").siblings('.select2').find(".select2-selection").css("border-color", "red");
                form_ok = false;
            }else{
                $("#garantias_2021 select[id='pais_warranty']").siblings('.select2').find(".select2-selection").css("border-color", "");
            }

            if (!$("#garantias_2021 input[type='checkbox']").is(":checked")) {
                $("#garantias_2021 input[type='checkbox']").parent().addClass('error');
                form_ok = false;
              } else {
                $("#garantias_2021 input[type='checkbox']").parent().removeClass('error');
              }

              $("#garantias_2021 input[type='text']").each( (i,item) => {
                let $item = $(item)
                if ($item.val() == "") {
                    $item.css("border-color", "red");
                    form_ok = false;
                } else {
                  $item.css("border-color", "");
                }
              });


            if (form_ok) {
                $("#garantias_2021 form button[type='submit']").prop('disabled', true);
                $("#garantias_2021 form button[type='submit']").closest('.wrap-btnDefault').addClass('loading');


                var options = {
                    codigo: $("#garantias_2021 input[name='codigo']").val(),
                    nombre: $("#garantias_2021 input[name='nombre']").val(),
                    apellidos: $("#garantias_2021 input[name='apellidos']").val(),
                    email: $("#garantias_2021 input[name='email']").val(),
                    phone: $("#garantias_2021 input[name='phone']").val(),
                    localidad: '1',
                    direccion: '1',
                    codigopostal: $("#garantias_2021 input[name='codigopostal']").val(),
                    pais: $("#garantias_2021 select[name='pais']").val(),
                    lang: $("#garantias_2021 input[name='lang']").val(),
                    valoracion: $("#garantias_2021 input[name='rate']:checked").val(),
                    valoracion_texto: $("#garantias_2021 textarea[name='rate_comment']").val(),
                    valoracion_producto: $("#garantias_2021 input[name='rate_product']").val(),
                }

                //fama.utils.debug('options' , options);


                $.ajax({
                    url: "/ajax.php?module=garantias&action=nueva&language=" + options.lang,
                    data: options,
                    type: "post",
                    async: true,
                    dataType: "json",
                    context: this,
                    beforeSend: function () {
                        $("#garantias_2021 form button[type='submit']").prop('disabled', true);
                    },
                    success: function (r) {
                        $botton.prop('disabled', false);
                        $("#garantias_2021 form button[type='submit']").closest('.wrap-btnDefault').removeClass('loading');
                        this.utils.toast.log(r.message);
                        //fama.utils.debug(r.status);
                       // alert($("#i18n_garantia_validada_texto").html());
                        $("#garantias_2021 input[name='codigo']").val("");
                        $("#garantias_2021 input[name='nombre']").val("");
                        $("#garantias_2021 input[name='apellidos']").val("");
                        $("#garantias_2021 input[name='email']").val("");
                        $("#garantias_2021 input[name='phone']").val("");
                        //$("#garantias input[name='localidad']").val("");
                        //$("#garantias input[name='direccion']").val("");
                        $("#garantias_2021 input[name='codigopostal']").val("");
                        $("#garantias_2021 input[name='rate']").prop('checked', false);
                        $("#garantias_2021 textarea[name='rate_comment']").val('');
                        $("#garantias_2021 input[name='rate_product']").val('');
                        $("#garantias_2021 input[name='acept']").prop('checked', false);

                        $("#garantias_2021 form button[type='submit']").prop('disabled', false);
                        $("#garantias_2021 input[type='checkbox']").prop('checked', false);

                        $('#garantias_2021 select[name="pais"]').find('option[value="0"]').attr('selected','selected');
                        $('#garantias_2021 select[name="pais"]').prop('selectedIndex',0);

                        $('#garantias_2021 select[name="pais"]').select2('destroy');
                        $('#garantias_2021 select[name="pais"]').find("select option").eq(0).html();
                        $('#garantias_2021 select[name="pais"]').select2();

                    },
                    error: () => {
                        this.utils.toast.error(e.message || e.responseJSON.message || 'Undefined error')
                    }

                });
            }else{
                $("#garantias_2021 form button[type='submit']").prop('disabled', false);
                $("#garantias_2021 form button[type='submit']").closest('.wrap-btnDefault').removeClass('loading');
            }

        })

    });
}
}
