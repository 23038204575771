// VENDOR

import { FamaUtils } from '../classes/FamaUtils.js'
import { Warranty } from './classes/Warranty.js';
import { NewsComment } from './classes/NewsComment.js';
import { BlogComment } from './classes/BlogComment.js';
import { FormContest } from './classes/FormContest.js';
import { Contact } from './classes/Contact.js';
// import { SliderMapStoreUI } from './classes/SliderMapStoreUI.js';
import { MapStore } from './classes/MapStore.js';
import { FAQ } from './classes/FAQ.js';
import { Contest } from './classes/Contest.js';
import { Instagram } from './classes/Instagram.js';
import { Resumee } from './classes/Resumee.js';
import { GalleryBoxFAQ } from './classes/GalleryBoxFAQ.js';
import { GalleryBox } from './classes/GalleryBox.js';
import { LoginStore } from './classes/LoginStore.js';

// require('./vendor/bootstrap.min.js'); //usa contact (fama)


//GSAP
require('../vendor/gsap/gsap.min.js');
require('../vendor/gsap/draggable.min.js');
require('../vendor/gsap/scrollTrigger.min.js');
require('../vendor/gsap/scrollMagic.min.js');



// typical import
import {gsap} from "gsap";
// get other plugins:
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {Draggable} from "gsap/Draggable";
// import {ScrollMagic} from "scrollmagic";
// import {InertiaPlugin} from "gsap/InertiaPlugin";

import {News} from './classes/News.js';
//import {News_Article_2021} from './news_article2021'; deleted
import {BlogManager} from '../classes/BlogManager.js';
import {BlogAuthorManager} from '../classes/BlogAuthorManager.js';
//import {Form} from './classes/Form.js';
import {Ratings} from '../classes/Ratings.js';

import { VideoManager } from "../classes/VideoManager.js";
import { SplashScreen } from "../classes/SplashScreen.js";
import { ModalFinder } from "../app/modules/ModalFinder.js";
import { MainMenu } from "./classes/MainMenu.js";
import { LibraryLoader } from '../classes/LibraryLoader.js';
import { Newsletter } from './classes/Newsletter.js';
import { Like } from '../classes/Like.js';
import { TopBar } from './classes/TopBar.js';
import { FadeTranslate } from '../classes/FadeTranslate.js';
import { SliderManager } from './classes/SliderManager.js';
import { Environment } from './classes/Environment.js';
import { HistoryPage } from './classes/HistoryPage.js';
import { DraggableSlider } from './classes/DraggableSlider.js';
import { ProductSideMenu } from '../classes/ProductSideMenu.js';
import { FavouriteHandler } from '../classes/FavouriteHandler.js';
import { ScrollBackgroundSphere } from '../classes/ScrollBackgroundSphere.js';
import { ProductDetail } from '../classes/ProductDetail.js';
import { ProductListManager } from '../classes/ProductListManager.js';
import { CategoryListManager } from '../classes/CategoryListManager.js';
import { GeneralRatingsForm } from './modules/GeneralRatingsForm.js';

let news = new News();
//let form = new Form();
let ratings = new Ratings();

// App
// var app = app || {};
// app.utils = FamaUtils.getInstance();

if(fama.utils) {
    fama.utils.toast.setSettings({style:{background:"#1197a5"}})
}


/**
 *  Splash screen. Initial Loader
 */

let splash = SplashScreen.getInstance()
splash.init()


// FROM INIT.JS
import {App} from "../app/App.js";
import { Animatable } from '../classes/Animatable.js';
import { VideoSlider } from './classes/VideoSlider.js';


/**
 * On Ready
 */

$((e) => {
    let loadLib = LibraryLoader.getInstance()

    require('./utils_2021.js');

    //Utils_2021 refactor
    let ft = new FadeTranslate()
    ft.init()

    //Slider Manager
    let slider = SliderManager.getInstance()
    slider.initAll()

    // FAMA
     console.log('************** fama.js loaded **************');
    let newsletter = new Newsletter()

    //app.Gallerybox.init(); //DUBLICA DE PUBLIC
    var $swiperContainer = $('.mygallerybox.faq');
    if ($swiperContainer.length > 0) {
        $swiperContainer.each(function(i, Slider) {
            //console.log('faq');
            let gb = new GalleryBoxFAQ($(Slider))
            // app.Gallerybox_2021_faq.init($(Slider));
        })
    }else{
        if($('.pasadorPost:not(.faq)')){
            let gb = GalleryBox.getInstance();
            // app.Gallerybox_2021.init();
        }
    }

    // Like handler
    if(fama.utils.existsOr(['.liked', '.like-button'])){
        let lk = new Like()
    }

    if($('isFama').length > 0) { //usa fama
        if($('#garantias_2021').length > 0){
            let warranty = new Warranty();
        }

        // if($("#comentario_noticia").length > 0){
        //     let news = new NewsComment();
        // }

        if(fama.utils.exists("#comentario_blog_2021")){
            let bcm = new BlogComment
        }

        if(fama.utils.exists("#faqs_2021")){
            let faq = new FAQ()
        }

        if(fama.utils.exists('#map')){
            loadLib.loadGoogleAsync().then(() => {
                let map = new MapStore();
            })
        }

        if(fama.utils.exists('#contact_2021')){
            let contact = new Contact()
        }

        if(fama.utils.exists('.upload-form_2021')){
            let fcm = new FormContest()
        }

        if(fama.utils.exists('.isContest')) { //usa fama
            let contest = new Contest();
        }

        if(fama.utils.exists('.curriculum_2021')) { //usa fama
            let resumee = new Resumee();
        }

        if(fama.utils.exists('.isHome')) { //usa fama
            let instagram = new Instagram();
        }

        if(fama.utils.exists('.sofas-medioambiente')) {
            let environment = new Environment()
        }

        if(fama.utils.exists('.timeline.nav')) {
            let hist = new HistoryPage()
        }

        if(fama.utils.exists('.box-logos')) {
            let hist = new DraggableSlider()
        }

        // FROM INIT.JS
        // Side menu
        if(fama.utils.exists('.sidebar-categories') ) {
            let sideMenu = new ProductSideMenu();
        }

        // Favourite Handler (Products)
        if(fama.utils.exists('.js-favourite')) {
            let fav = new FavouriteHandler();
        }

        if(fama.utils.exists('.js-animatable')) {
            let animatable = new Animatable()
        }

        // Scroll Sphere
        if(fama.utils.existsOr(['.ballon-list','.ballon-top','.ballon-bottom'])) {
            let scrollSphere = new ScrollBackgroundSphere();
        }

        // Product detail (Loaded in products)
        if(fama.utils.existsOr(['app-detail_2021', 'app-detail'])){
            let pd = new ProductDetail();
        }

        // List Manager
        if(fama.utils.existsOr(['.js-listGrid','.js-listList'])) {
            let lm = new ProductListManager();
        }

        if( fama.utils.exists('.catalogos-slider')) {
            let cm = new CategoryListManager();
        }

        let ratings = new Ratings();
        if(fama.utils.exists('#generalRatingID')) {
            // form.init();
            let grf = new GeneralRatingsForm();
            ratings.init();
        }

        window.app = new App({
            breakpointDesktop: 769,
        });
        app.init()

    }

    let loginStores = new LoginStore()
    let searchParams = new URLSearchParams(window.location.search)
    if(!window.gLogged && searchParams.has('login')) loginStores.openClose();
    // }

    if($('.company-item.block').length > 0) {//use fama
        function parcheRSC() {
            //Parche RSC
            var h = $(".company-item.block").first().find(".photo img").height();

            $(".company-item.block").find(".photo img").height(h);
            $(".company-item.block").first().find(".photo img").css("height", "");

        }
        $(window).on("load", parcheRSC);
        $(window).on("resize", parcheRSC);
    }

    //----------------------------------------------------
    // init GSAP
    gsap.registerPlugin(ScrollTrigger,);
    //gsap.registerPlugin(InertiaPlugin);
    gsap.registerPlugin(Draggable)

    // init Videos Manager
    if(fama.utils.existsOr(['.js-videosWrapper'])) {
        let vm = new VideoManager();
        window.l = vm
    }


    if(fama.utils.exists('.js-play')) {
        let v = new VideoSlider();
    }



    if(fama.utils.exists('#newsID')) {
        news.init();
    }

    if(fama.utils.exists('#js-blog')) {
        let blog = new BlogManager();
    }

    if(fama.utils.exists('#js-blog-author')) {
        let author = new BlogAuthorManager();
    }

    /*
    if(fama.utils.exists('.js-ratingForm')) {
        form.init();
        ratings.init();
    }
    */

    // let modal = new ModalFinder();
    // modal.init();

    let topBar = new TopBar();

    // Burger Menu
    let mainMenu = new MainMenu();

});
