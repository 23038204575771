import { FamaUtils } from "../../classes/FamaUtils";
import { ToastManager } from "../../classes/ToastManager";

export class BlogComment {
    utils = window.fama.utils ?? FamaUtils.getInstance();

    constructor() {
        fama.utils.debug("BlogComment loaded")
        this.init()
    }


    init() {
        fama.utils.debug('app.Blog_2021');
        $("#comentario_blog_2021").each((i, item) => {

            $("#comentario_blog_2021 .js-comentario_blog_2021").off().on('click', (e) => {
                e.preventDefault();

                var form_ok = true;
                if (!$("#comentario_blog_2021 input[type='checkbox']").is(":checked")) {
                    $("#comentario_blog_2021 input[type='checkbox']").parent().addClass('error');
                    form_ok = false;
                }

                else {
                    $("#comentario_blog_2021 input[type='checkbox']").parent().removeClass('error');
                }
                // fama.utils.debug('form_ok1', form_ok);
                $("#comentario_blog_2021 input[type='text']").each((i, item) => {
                    let $item = $(item)
                    if ($item.val() == "") {
                        $item.css("border-color", "red");
                        form_ok = false;
                    }
                    else {
                        $item.css("border-color", "");
                    }
                });

                $("#comentario_blog_2021 textarea[type='text']").each((i,item) => {
                    let $item = $(item)
                    if ($item.val() == "") {
                        $item.css("border-color", "red");
                        form_ok = false;
                    }
                    else {
                        $item.css("border-color", "");
                    }
                });

                $("#comentario_blog_2021 input[type='email']").each((i,item) => {
                    let $item = $(item)
                    if ($item.val() == "") {
                        $item.css("border-color", "red");
                        form_ok = false;
                    }
                    else {
                        if(!this.utils.isEmailValid($item.val())){
                            $item.css("border-color", "red");
                            form_ok = false;
                        }else{
                            $item.css("border-color", "");
                        }
                    }
                });
                if (form_ok) {
                    var form = $("#comentario_blog_2021").serialize();
                    this.changeButtonState('off')
                    this.send();
                }
            });
        });
    }

    /**
     * Ajax call
     */
    send() {
        fama.utils.debug('app.Blog_2021.send');
        var options = {
            blogid: $("#comentario_blog_2021 input[name='idblog']").val(),
            siteid: $("#comentario_blog_2021 input[name='idsite']").val(),
            lang: $("#comentario_blog_2021 input[name='lang']").val(),
            alias: $("#comentario_blog_2021 input[name='alias']").val(),
            email: $("#comentario_blog_2021 input[name='email']").val(),
            comentario: $("#comentario_blog_2021 textarea[name='comentario']").val()
        }
        fama.utils.debug(options);
        $.ajax({
            url: "/ajax.php?module=blog&action=comment&language=" + options.lang,
            data: options,
            type: "post",
            async: true,
            dataType: "json",
            beforeSend: () => {

            },
            success: (r) => {
                let lastLomment=$('#comentario_blog_2021').closest('.comments').find('.coment-item:last');
                let numComment=parseInt($("#comentario_blog_2021").closest('.wrap-comments-content').find('.numComments').attr('data-num'));

                if (lastLomment.length > 0){
                    $("#comentario_blog_2021").closest('.comments').find('.wrap-comments').html(r.commenthtml);
                    let comments=$("#comentario_blog_2021").closest('.wrap-comments-content').find('.numComments').attr('data-comments');
                    $("#comentario_blog_2021").closest('.wrap-comments-content').find('.title-comments').find('.title').html(comments);
                }else{
                    $("#comentario_blog_2021").closest('.comments').prepend(r.commenthtml);
                    let comment=$("#comentario_blog_2021").closest('.wrap-comments-content').find('.numComments').attr('data-comment');
                    $("#comentario_blog_2021").closest('.wrap-comments-content').find('.title-comments').find('.title').html(comment);

                }

                numComment++;
                // alert($("input[name='comentario_publicado']").val());
                this.utils.toast.log($("input[name='comentario_publicado']").val())
                $("#comentario_blog_2021").closest('.wrap-comments-content').find('.numComments').attr('data-num', numComment);
                $("#comentario_blog_2021").closest('.wrap-comments-content').find('.numComments').html(numComment);
                $("#comentario_blog_2021").closest('.comments').find('.no-comments').hide();
                $("#comentario_blog_2021").closest('.wrap-comments-content').find('.title-comments').addClass('isSee');

                $("#comentario_blog_2021")[0].reset();
            },
            complete: () => {
                this.changeButtonState('on');
            }
        });
    }

    /**
     * Change button state
     * @param {string} state on | off
     */
    changeButtonState(state) {
        let $button = $("#comentario_blog_2021 .js-comentario_blog_2021");
        switch(state) {
            case "on":
                $button.prop('disabled', false)
                $button.closest('.wrap-btnDefault').removeClass('loading');
                break

            case "off":
                $button.prop('disabled', true)
                $button.closest('.wrap-btnDefault').addClass('loading');
                break
        }
    }
}
