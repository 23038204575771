
export class ModalFinder {
    /**
     * USE ALL (fama, famaliving)
     *
     * @param options [Object]
     * @param app [App]
     *
     *
     */
    constructor(options, app) {
        console.log("ModalFinder loaded")
        this.defaultOptions = {
            urlJson: '/data/search_' + window.location.hostname + '_' + $('html').attr('lang') + '.json',
            btnCloseSelector: '.js-finder-close',
            btnOpenSelector: '.js-finder-open',
            modalFinderSelector: '.modal-finder',
            inputSelector: '.modal-finder .js-finder',
            outputSelector: '.modal-finder .results',
            nonResultMessageSelector: '.modal-finder .non-results',
        };
        this.options = options || this.defaultOptions;
        this.app = app;
        this.data = null;

        //console.log("urljson "+this.getUrlJson());

    }

    /**
     * Remove all accents of a string.
     * @param str {string}
     * @returns {string}
     */
    removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    getUrlJson() {
        //console.log('getUrlJson', this.options.urlJson);
        return this.options.urlJson || this.defaultOptions.urlJson;
    }

    getBtnClose() {
        return $(this.options.btnCloseSelector || this.defaultOptions.btnCloseSelector);
    }

    getBtnOpen() {
        return $(this.options.btnOpenSelector || this.defaultOptions.btnOpenSelector);
    }

    getModal() {
        return $(this.options.modalFinderSelector || this.defaultOptions.modalFinderSelector);
    }

    getInput() {
        return $(this.options.inputSelector || this.defaultOptions.inputSelector);
    }

    getOutput() {
        return $(this.options.outputSelector || this.defaultOptions.outputSelector);
    }

    getNonResults() {
        return $(this.options.nonResultMessageSelector || this.defaultOptions.nonResultMessageSelector);
    }

    /**
     *
     * @param val string
     */
    search(val) {
        if (this.data) {
            let value = val.toLowerCase();
            let results = [];
            let self = this;

            $.each(this.data, function (index, cat) {
                let catFiltered, catSort;
                catFiltered = cat.filter(item => {
                    let nombre = self.removeAccents(item.nombre || '');
                    let subnombre = self.removeAccents(item.subnombre || '');
                    let descripcion = self.removeAccents(item.descripcion || '');
                    let val = self.removeAccents(value);

                    if (-3 < nombre.toLowerCase().search(val) + subnombre.toLowerCase().search(val) + descripcion.toLowerCase().search(val)) {
                        item.finderRelevance = 0;

                        if (nombre.toLowerCase().trim() == val.trim()) {
                            item.finderRelevance += 1000;
                        }

                        if (subnombre.toLowerCase().trim() == val.trim()) {
                            item.finderRelevance += 100;
                        }

                        if (nombre.toLowerCase().search(val) > -1) {
                            item.finderRelevance += 10;
                        }

                        if (subnombre.toLowerCase().search(val) > -1) {
                            item.finderRelevance += 1;
                        }

                        return true;
                    }

                    return false;
                });

                catSort = catFiltered.sort(function (a, b) {
                    return b.finderRelevance - a.finderRelevance;
                });

                results = results.concat(catSort);
            });

            return results;
        }
    }

    /**
     *
     * @param results array
     * @param $output
     */
    renderResults(results, $output, val=null) {
        if($('isFama').length > 0 || $('isFamaliving').length > 0) {
            let valSearch=val;
            let noImage = ($('isFama').length > 0) ? 'no_photo.svg': 'fl-no_photo.svg'
              //Example: $('<div class="item"><img src="https://www.homebyfama.com/resources/files/shares/products/mantas/nonne/manta-nonne-170x13009.jpg" class="image"><span class="item-name">Sofa Manacor</span></div>');
            $output.find('.wrap-category').remove();

            let catArray=new Map();
            let arr=[];
            let resultSing=$('.modal-finder .wrapp').attr('data-sing');
            let resultPlur=$('.modal-finder .wrapp').attr('data-plur');
            let msjNoResultStrong=$('.modal-finder .wrapp').attr('data-strong');
            let msjNoResultFindNew=$('.modal-finder .wrapp').attr('data-new');

            // filtro array de results por categorias
            //console.log('results fama', results);
            results.forEach(function(item){
                let subnombre=item.subnombre;
                if(!catArray.has(subnombre)){
                    arr=[];
                }
                arr.push(item);
                catArray.set(subnombre, arr);
            });

            //let textSearch=$('.modal-finder input[name="finder"]').val();

            let $nonResultMessage = this.getNonResults();

            let textResult=$('.text-result');
            let textResultInfo=textResult.find('.text-search');
            //textSearch='"'+textSearch+'"';
            valSearch='"'+valSearch+'"';
            textResultInfo.html(valSearch);
            textResult.fadeIn();

            let $categoryWrap;
            let $category;
            let $title;
            let $num;
            let $wrapNum;


            catArray.forEach( (value, key) => {
                $categoryWrap = $('<div class="wrap-category"></div>');
                $category = $('<div class="category d-flex"></div>');
                $title=$('<span class="title"></span>');
                $wrapNum=$('<span class="num"></span>');
                $num=value.length;

                $title.html(key+"\&nbsp");

                if($num===1){
                    $wrapNum.html('(' + $num+"\&nbsp" + resultSing +')');
                }else{
                    $wrapNum.html('(' + $num+"\&nbsp" + resultPlur +')');
                }


                $category.append($title);
                $category.append($wrapNum);
                $categoryWrap.append($category);

                $.each(value, (index, item) => {

                    if (!!item.nombre.trim()) {
                        let $itemDom = $('<a>');
                        let $imagenWrap = $('<div class="wrap-img"></div>');
                        let $imagenWrapAux = $('<div class="wrap-img-aux"></div>');
                        let $imagen = $('<img>');
                        $itemDom.attr('href', item.link);
                        $itemDom.addClass('item');
                        if (!!item.imagen) {
                            $imagen.attr('src', item.imagen);
                            $imagen.attr('alt', item.nombre);
                            $imagen.addClass('image')
                        } else {
                            $imagen.attr('src', '/images/'+noImage);
                            $imagen.attr('alt', 'no photo');
                            $imagen.addClass('image')
                            //$imagen = $('<div class="imagen"></div>');
                        }
                        $imagenWrapAux.append($imagen);
                        $imagenWrap.append($imagenWrapAux);
                        $itemDom.append($imagenWrap);

                        $itemDom.append('<div class="item-name">' +  item.nombre + '</div>');
                        $itemDom.css('display', 'block');

                        $categoryWrap.append($itemDom);
                    }

                });
                $output.append($categoryWrap);
            });

            if (catArray.size > 0) {
                $nonResultMessage.hide();
                $nonResultMessage.html('');
                $output.fadeIn();
            } else {
                let msjNoRes=`<div class="wrap-NoResult"><div><strong>`+msjNoResultStrong+`</strong></div><div>`+msjNoResultFindNew+`</div></div>`;
                $nonResultMessage.html(msjNoRes);
                $nonResultMessage.fadeIn();
                $output.fadeOut();
            }
        // Products & no tagged layouts
        } else {
            //Example: $('<div class="item"><img src="https://www.homebyfama.com/resources/files/shares/products/mantas/nonne/manta-nonne-170x13009.jpg" class="image"><span class="item-name">Sofa Manacor</span></div>');
            $output.find('.item').remove();
            //console.log('render famaliving');
            let $nonResultMessage = this.getNonResults();

            $.each(results, (index, item) => {
                //console.log('render famaliving if');
                if (!!item.nombre.trim()) {
                    let $itemDom = $('<a>');
                    let $imagen = $('<img>');
                    $itemDom.attr('href', item.link);
                    $itemDom.addClass('item');
                    if (!!item.imagen) {
                        $imagen.attr('src', item.imagen);
                        $imagen.attr('alt', item.nombre);
                        $imagen.addClass('image')
                    } else {
                        $imagen = $('<div class="imagen"></div>');
                    }
                    $itemDom.append($imagen);

                    let subnombre = '';
                    if (item.subnombre) {
                        subnombre = '<strong>' + item.subnombre + '</strong> - ';
                    }

                    $itemDom.append('<span class="item-name">' + subnombre + item.nombre + '</span>');
                    $itemDom.css('display', 'block');

                    $output.append($itemDom);
                }

            });

            if (results) {
                //console.log('results', results);
                $nonResultMessage.hide()
                $output.fadeIn();
            } else {
                $nonResultMessage.fadeIn();
                $output.fadeOut();
            }
        }

    }

    init() {

        let self = this;
        let $modal = this.getModal();
        let $close = this.getBtnClose();
        let $open = this.getBtnOpen();
        let $input = this.getInput();
        let $output = this.getOutput();
        let $nonResultMessage = this.getNonResults();

        let $bodyHtml=$('body, html');
        let $topbar=$('.topbar');

        $.getJSON(this.getUrlJson(), function (data) {
            self.data = data;
            if (data && $input.val().length >= 3) {
                self.renderResults(self.search($input.val()), $output, $input.val());
            }
        });

        $input.off('keyup').on('keyup', (e) => {
            let val = e.currentTarget.value;
            if (val.length >= 3) {
                self.renderResults(self.search(val), $output, val);
            }

            if (val === '') {
                $output.fadeOut();
                $nonResultMessage.fadeOut();

                let textResult=$('.text-result');
                let textResultInfo=textResult.find('.text-search');
                textResultInfo.html('');
                textResult.fadeOut();
            }
        });

        if($('isFama').length > 0) {
            $close.off('click').on('click', () => {
                $modal.fadeOut();
                $output.hide();
                $('.full-level-black-search').removeClass('isVisible');
                $('.close.noSeeDesk').fadeOut();
                $('.modal-finder .text-result').html('');
                $('body').removeClass('is-open-modal');
                $('body').removeClass('stopScrollSearch');
                $('.menu-burger').removeClass('notVisible');
                $input.val('');
            });

            $open.off('click').on('click', (e) => {
                $modal.fadeIn();
                $input.trigger('focus');
                $('.full-level-black-search').addClass('isVisible');
                $('body').addClass('is-open-modal');
                $('body').addClass('stopScrollSearch');
                $('.stopScrollSearch .menu-burger').addClass('notVisible');
                $('.close.noSeeDesk').fadeIn();

            });
        //Famaliving
        } else {
            $close.off('click').on('click', () => {
                //console.log('close');
                $modal.fadeOut();
                $output.hide();
                $bodyHtml.removeClass('stop-scroll');
                $input.val('');
                $topbar.removeClass('navAdapt');

                let textResult=$('.text-result');
                let textResultInfo=textResult.find('.text-search');
                textResultInfo.html('');

                $close.hide();
                $open.show();

                $topbar.find('.full-level-black').removeClass('isVisible');


            });

            $open.off('click').on('click', (e) => {
                //console.log('open');
                $modal.fadeIn();
                $input.trigger('focus');
                $bodyHtml.addClass('stop-scroll');

                if($topbar.hasClass('is-open')){
                    $topbar.toggleClass('is-open');
                    $topbar.find('.full-level-black').removeClass('isVisible');
                }

                if(window.innerWidth<992 || document.documentElement.clientWidth<992 || document.body.clientWidth<992 || document.body.offsetWidth<992){

                    $topbar.find('.full-level-black').addClass('isVisible');
                }

                if($('.wrap-store.is-open').length>0){
                    $('.js-storeClose').trigger('click');
                }

                $('.btn-search').hide();

                $close.show();
                $close.addClass('is-open');

            });
        }
    }
}
