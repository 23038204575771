export class MainMenu {

    selectors = {
        menu: ".js-open-menu",
        topbar: ".topbar"
    }

    /**
     *  constructor
     */
    constructor(){
        fama.utils.debug("Main Menu loaded")
        this.init()
    }

    /**
     * Initialize
     */
    init() {
            // Main Burger menu
        $(this.selectors.menu).off('click').on('click', (e) => {
            // setTimeout(function(){
                $(this.selectors.topbar).toggleClass('is-open');
                $(this.selectors.topbar).find('.full-level-black').toggleClass('isVisible');
                $('html body').toggleClass('stopScroll');
                $(this.selectors.topbar + ' .wrap-items').toggleClass('notVisible');
                //if you stay on any page of company and have open menu movil, the submenu of company will be open automaticly

                if($('body').hasClass('company-all') && $(this.selectors.topbar).hasClass('is-open')){
                    //fama.utils.debug('empresa');
                    $('.menu-categories-productos.mvl').find('.item.empresa').addClass('isOpen');
                }
            // },1)
        });

        // Submenu
        $('.js-open-submenu').on('click', (e) => {
            let $element = $(e.currentTarget)
            e.preventDefault();
            let $submenu = $element.closest('.item').toggleClass('is-open').find('ul')
            if($submenu.is(':visible')){
                $submenu.stop().slideUp();
            }
            else{
                $submenu.stop().slideDown();
            }
        });

        //Mobile submenu
        $(this.selectors.topbar + ' .js-dropDown').on('click', (e) => {
            $(e.currentTarget).toggleClass('isOpen');
        });
    }
}
