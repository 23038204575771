'use strict'

import { FamaUtils } from "../../classes/FamaUtils";

// use FAMA REDESIGN 2021 famaWeb-new en generalRatings.blade.php
/**
 * Used in GeneralRatings module. Form handler
 * @name GeneralRatingsForm
 */
export class GeneralRatingsForm
{
    /** Selectors **/
    $formContainer() { return document.querySelectorAll('.js-formContainer'); }
    $articleToHide() { return document.querySelectorAll('.js-articleToHide'); }
    $mobileFormContainer() { return document.querySelectorAll('.js-mobileFormContainer'); }
    $formBtn() { return document.querySelectorAll('.js-formOpener'); }
    $mFormCloseBtn() { return document.querySelectorAll('.js-closeMobileForm'); }
    $starInput() { return document.querySelectorAll('.js-starInput'); }
    $sendRating() { return $('.js-sendRating'); }
    $formInputs() { return document.querySelectorAll('.js-inputValue'); }
    $formInputsMobile() { return document.querySelectorAll('.js-inputValueMobile'); }
    $photoInputField() { return $(`.js-photoInput`); }
    $photoLabelField() { return $(`.js-photoInputLabel`); }
    $warrantyInput() { return $(`.js-warrantyCode`); }

    /**  Variables */
    utils = window.fama.utils ?? FamaUtils.getInstance()
    formSelector = (window.innerWidth < 991) ? '.mobileForm' : '.form-rating_2021'
    $isValid=true;

    /** Api Endpoints **/
    $apiSendRating() { return 'api/send-rating' }

    /** Web Endpoints **/
    webInit() { return '/' }

    /**
     *
     */
    constructor()
    {
        console.log("GeneralRatingsForm loaded")
        this.eventListeners();
        let $hasContent=$($('.headerRatings_2021')[0]).attr('data-contest');
    }

    /**
     * Events
     */
    eventListeners()
    {

        // Toggle form
        this.$formBtn().forEach(element => {
            element.addEventListener('click', e => {
                this.showAccordeon(element.value)
            });
        });

        // Mobile close form
        this.$mFormCloseBtn()[0].addEventListener('click', e => {
            this.closeAccordeon();
        });

        this.$sendRating().on('click', e => { this.sendRating(e) })

        let $hasContent = $($('.headerRatings_2021')[0]).attr('data-contest');
        if($hasContent === "1"){
            let photo;
            // let $form = (window.innerWidth < 991) ? '.mobileForm' : '.form-rating_2021'
            photo = $(this.formSelector + ' .js-photoInput')[0];
            photo.addEventListener('change', e => {
                this.disableWarranty();
            });

            this.$warrantyInput()[0].addEventListener('change', e => {
                this.disablePhoto();
            });
        }

    }

    /**
     * Disable warranty box if there is no content
     */
    disableWarranty() {
        let warranty;
        let photo;
        let photoLabel;
        let $hasContent = $($('.headerRatings_2021')[0]).attr('data-contest');

        if($hasContent === "1"){
            warranty = $(this.formSelector + ' .js-warrantyCode')[0];
            photo = $(this.formSelector + ' .js-photoInput')[0];
            photoLabel = $(this.formSelector + ' .js-photoInputLabel')[0];

            if(photo.value !== '') {
                warranty.disabled = true;
                let reader = new FileReader();

                reader.onload = (e) => {
                    console.log('photoLabel', photoLabel);
                    photoLabel.innerHTML = '';
                    photoLabel.style.backgroundImage = `url(${e.target.result})`;
                    photoLabel.style.height = '300px';
                };
                reader.readAsDataURL(photo.files[0]);
            }
            else { warranty.disabled = false; }

        }
        else { warranty=$(this.formSelector + ' .js-warrantyCode')[0]; }
    }

    /**
     *
     */
    disablePhoto() {
        let warranty;
        let photo;
        let inputs;
        let photoLabel;

        warranty=$(this.formSelector + ' .js-warrantyCode')[0];
        photo=$(this.formSelector + ' .js-photoInput')[0];
        photoLabel=$(this.formSelector + ' .js-photoInputLabel')[0];

        if(warranty.value !== '') {
            photo.disabled = true;
        }
        else {
            photo.disabled = false;
        }
    }

    /**
     *
     * @param {*} event
     * @returns
     */
    sendRating(event)
    {
        let data = this.getData();
        let $hasContent=$($('.headerRatings_2021')[0]).attr('data-contest');
        console.log('sendRating', this.$isValid);
        console.log('sendRating2', data);

        if(!data){return};

        console.log('sendRating', this.$isValid);

        if(this.$isValid){
            console.log('aqui valido');
            if($hasContent==="1"){
                let reader = new FileReader();

                reader.onloadend = () => {
                    data.image = reader.result;
                    this.postRating(data);
                };

                if(data.image instanceof File) {
                    data.image_name = data.image.name;
                    reader.readAsDataURL(data.image);
                }
                else {
                    this.postRating(data);
                }
                console.log('data1', data);
            }else{
                console.log('data',data);
                this.postRating(data);
            }

        }

    }

    postRating(data) {
        console.log('postRating', data);
        let errComent = $('.headerRatings_2021').attr('data-errServer');

        data.lang = document.documentElement.lang;
        $('.wrap-btnDefault.sendBtn').addClass('loading');
        $('.btnDefault.js-sendRating').css('disabled', true);

        //Ajax call
        axios
            .post(this.$apiSendRating(), data)
            .then( (response) => {
                console.log('response', response.data.status);
                $('.wrap-btnDefault.sendBtn').removeClass('loading');
                // if(response.data.status==='ok'){

                $('.btnDefault.js-sendRating').css('disabled', false);
                $('.thanks')[0].style.display = 'block';
                $('.js-ratingForm').addClass('hidden');
                this.closeAccordeon();
                // }

            })
            .catch( (error) => {
                console.log(error.response.data.message)
                console.log(error.response.data.errors);

                this.utils.toast.log(errComent);
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    console.log(`${key} ${value}`);
                    $('.headerRatings_2021 input[name="'+`${key}`+'"]').css('border','1px solid red');
                    $('.headerRatings_2021 input[name="'+`${key}`+'"]').siblings('small').fadeIn();

                    $('.headerRatings_2021 textarea[name="'+`${key}`+'"]').css('border','1px solid red');
                    $('.headerRatings_2021 textarea[name="'+`${key}`+'"]').siblings('small').fadeIn();

                }

                $('.wrap-btnDefault.sendBtn').removeClass('loading');
                $('.btnDefault.js-sendRating').css('disabled', false);
            })
        $('.wrap-btnDefault.sendBtn').removeClass('loading');
        $('.btnDefault.js-sendRating').css('disabled', false);

        $('html, body').animate({scrollTop:0}, 'slow');
    }


    /**
     *
     * @returns
     */
    getData() {
        let inputs;
        let warranty="";
        let photo="";
        let photoLabel="";
        let $hasContent=$($('.headerRatings_2021')[0]).attr('data-contest');

        let data = {};
        let count = 0;

        if(window.innerWidth < 975) {
            inputs = this.$formInputsMobile();

            if($hasContent==="1"){
                warranty=$('.mobileForm .js-warrantyCode')[0];
                photo=$('.mobileForm .js-photoInput')[0];
                photoLabel=$('.mobileForm .js-photoInputLabel')[0];
            }else{
                warranty=$('.mobileForm .js-warrantyCode')[0];
            }
        }
        else {
            inputs = this.$formInputs();
            warranty=$('.form-rating_2021 .js-warrantyCode')[0];
            if($hasContent === "1"){
                photo=$('.form-rating_2021 .js-photoInput')[0];
                photoLabel=$('.form-rating_2021 .js-photoInputLabel')[0];
            }
        }

        inputs.forEach(element => {
            let errorWarning = $(`.js-error${element.name}`)[0];
            if(!this.validate(element)) {
                if($hasContent==="1"){
                    if(element.name === 'warranty_code') {
                        //console.log('warranty_code');
                        let photoInput = photo;
                        if(photoInput.value === '') {
                            element.style.border='1px solid red';
                            errorWarning.style.display = 'unset';
                            photoLabel.style.border='1px solid red';
                        }
                        else {
                            photoLabel.style.border='1px solid #575252';
                            data['image'] = photoInput.files[0];
                        }
                    }
                    else {
                        count++;
                        element.style.border='1px solid red';
                        errorWarning.style.display = 'unset';
                    }

                    //check empty fields
                    if(photo.value == '' && warranty.value == ''){
                        photoLabel.style.border='1px solid red';
                        warranty.style.border='1px solid red';
                        this.$isValid=false;
                    }else{
                        photoLabel.style.border='1px solid #575252';
                        warranty.style.border='1px solid #575252';
                        this.$isValid=true;
                    }
                }else{
                    console.log('count++', element);
                    count++;
                    element.style.border='1px solid red';
                    errorWarning.style.display = 'unset';
                    this.$isValid=false;
                }

            }
            else {
                if($hasContent==="1"){
                    element.style.border='1px solid #575252'
                    errorWarning.style.display = 'none';
                    data[element.name] = element.value;
                    photoLabel.style.border='1px solid #575252';

                    //Check empty fields
                    if(photo.value == '' && warranty.value == ''){
                        photoLabel.style.border='1px solid red';
                        warranty.style.border='1px solid red';
                        this.$isValid=false;
                    }else{
                        photoLabel.style.border='1px solid #575252';
                        warranty.style.border='1px solid #575252';
                        this.$isValid=true;
                    }
                }else{
                    element.style.border='1px solid #575252'
                    errorWarning.style.display = 'none';
                    data[element.name] = element.value;
                    this.$isValid=true;
                }
            }


        });

        if(count > 0) {
            console.log('count', count);
            $(`.js-errorMobile`)[0].style.display = 'unset';
            return null;
        }
        else {
            console.log('count2', count);
            console.log('data', data);
            return data;
        }
    }

    /**
     * Validate fields
     * @param {*} element
     * @returns
     */
    validate(element) {
        switch(element.name) {
            case 'email':
                let re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                return re.test(String(element.value).toLowerCase());
            case 'privacy':
                return(element.checked)
            case 'privacyMobile':
                return(element.checked)
            default:
                if(element.value === '' || element.value === null ||element.value === '0'){return false};
                break;
        }
        return true;
    }


    /**
     *
     * @param {*} starNumber
     */
    showAccordeon(starNumber) {

        this.$starInput().forEach(element => {
            element.value = starNumber;
        });

        if(window.innerWidth < 975) {
            this.$mobileFormContainer()[0].classList.remove('hidden');
            this.$articleToHide()[0].classList.add('hidden');
        }
        else {
            if(this.$formContainer()[0].classList.contains('hiddenAccordeon')){
                this.$formContainer()[0].classList.remove('hiddenAccordeon');
            }
            else if(starNumber == 0) { // Checkeo si el boton es el de escribir una opinion
                this.$formContainer()[0].classList.add('hiddenAccordeon');
            }
        }

        this.$formBtn().forEach(element => {
            if(element.value <= starNumber && element.value != 0) {
                element.style.backgroundImage = 'url(/css/assets/coloredStar.svg)';
            }
            else if(element.value !== '0') {
                element.style.backgroundImage = 'url(/css/assets/star.svg)';
            }
        });
    }

    /**
     *
     */
    closeAccordeon() {
        this.$mobileFormContainer()[0].classList.add('hidden');
        this.$articleToHide()[0].classList.remove('hidden');
    }

}
