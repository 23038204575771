// import 'slick-carousel'

export class SliderMapStoreUI {
    constructor() {
        this.eventListeners()
        this.init()
    }

    /**
     *
     */
    eventListeners(){
        $('.js-openFull').on('click', (e) => this.seeFullImage(e) );
        $('.js-closeFull').on('click', (e) => this.closeFullImage(e) );
    }

    /**
     *
     */
    init(){
        if($('.slider-shop').length > 0){
            $('.slider-shop').slick({
                centerMode: false,
                slidesToShow: 1,
                infinite: false,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                focusOnSelect: true,
                arrows: true,
                autoplay: false,

                draggable: false,
                touchMove: false,
                swipeToSlide: false,
                swipe: false,

                variableWidth: true,

            })

            var $slider=$('.slider-shop');
            var $progressBar = $('.map-container-info .progress');
            var $progressBarLabel = $( '.map-container-info .slider__label' );

            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

                $progressBar
                    .css('background-size', calc + '% 100%')
                    .attr('aria-valuenow', calc );

                $progressBarLabel.text( calc + '% completed' );
            });
        }
    }

    seeFullImage(e){
        let $element = $(e.currentTarget)
        var imgFullBackground;
        var $slider=$element.closest('.slider-shop');;
        var srcCurrentImg=$element.find('img').attr('src');
        //fama.utils.debug('srcCurrentImg: ', srcCurrentImg);

        imgFullBackground=$element.closest('.container-shops').find('.wrap-full-background');
        imgFullBackground.find('.imgActive').attr('src', srcCurrentImg);
        imgFullBackground.addClass('isOpen');

    }

    closeFullImage(e){
        let $element = $(e.currentTarget)
        //fama.utils.debug('seeFullImage');
        var imgFullBackground=$element.closest('.wrap-full-background');
        imgFullBackground.removeClass('isOpen');
    }


}
