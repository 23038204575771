import { FamaUtils } from "../../classes/FamaUtils";
import { ToastManager } from "../../classes/ToastManager";

export class Contact {
    utils = window.fama.utils ?? FamaUtils.getInstance()

    constructor() {
        //this.utils.toast.log("Contact Manager Loaded")
        this.init()
    }

    init() {
        //fama.utils.debug('app.Contact_2021');
        $("#contact_2021").each( (i, item) => {
        //fama.utils.debug('app.Contact_2021');
        let $item = $(item)
        if ($item.hasClass("newsletter") || $item.hasClass("garantias")) return false;
        var id = $item;
        if (!this.utils.isLoad("select2", "$")) return;
        id.find("select[name='pais']").select2({ width: 'resolve'} );
        id.find("select[name='departamento']").select2();


        $("#contact_2021 form button[type='submit']").off().on('click', (e) => {
            e.preventDefault();
            var form_ok = true;
            let $checkbox = $("#contact_2021 input[type='checkbox']")

            if (!$checkbox.is(":checked")) {
                $checkbox.parent().addClass('error');
                form_ok = false;
            } else {
                $checkbox.parent().removeClass('error');
            }

            $("#contact_2021 input[type='text'], #contact_2021 textarea").each((i,item) => {
                let $element = $(item)
                if ($element.val() == ""
                    && ($element.attr("name") == "nombre"
                        || $element.attr("name") == "apellidos"
                        || $element.attr("name") == "comentario"))
                {
                    $element.addClass('error');
                    form_ok = false;
                } else {
                    $element.removeClass('error');
                }
            });

            if ($.isNumeric($("#contact_2021 select[name='pais']").val() == "")
                || $("#contact_2021 select[name='pais']").val() <= 0)
            {
                $('#contact_2021 select[name="pais"]')
                .closest('.form-group')
                .find('.select2 .select2-selection')
                .css("border-color", "red");
                form_ok = false;
            }else{
                    $('#contact_2021 select[name="pais"]')
                    .closest('.form-group')
                    .find('.select2 .select2-selection')
                    .css("border-color", "#575252");
            }

            $("#contact_2021 input[type='email']").each( (i, item) => {
                let $element = $(item)
                if (($element.val().trim()) == "") {
                    $element.addClass('error');
                    form_ok = false;
                }
                else {
                    if(!this.utils.isEmailValid($element.val())){
                        $element.addClass('error');
                        form_ok = false;
                    }else{
                        $element.removeClass('error');
                    }
                }
            });


            // $(this).css('disabled', true);
            this.changeButtonState('off')
            if (form_ok) {
                this.callAjax()
            }else{
                this.changeButtonState('on')
            }
        });
      });
    }

    callAjax() {
        var options = {
        nombre        : $("#contact_2021 input[name='nombre']").val(),
        apellidos     : $("#contact_2021 input[name='apellidos']").val(),
        email         : $("#contact_2021 input[name='email']").val(),
        departamento  : $("#contact_2021 select[name='departamento']").val(),
        telefono      : $("#contact_2021 input[name='telefono']").val(),
        pais          : $("#contact_2021 select[name='pais']").val(),
        comentario    : $("#contact_2021 textarea[name='comentario']").val(),
        region        : $("#contact_2021 input[name='region']").val(),
        city          : $("#contact_2021 input[name='city']").val(),
        lang          : $("#contact_2021 input[name='lang']").val()
        };

        $.ajax({
            url: "/ajax.php?module=contacto&action=contact&language=" + options.lang,
            data: options,
            type: "post",
            async: true,
            dataType: "json",
            beforeSend: function beforeSend() {
                $("#contact_2021 form button[type='submit']").prop('disabled', true);
            },
            success: (r) => {
                $("#contact_2021 form button[type='submit']").closest('.wrap-btnDefault').removeClass('loading');
                this.utils.toast.log(r.message);

                $("#contact_2021 input[name='nombre']").val("");
                $("#contact_2021 input[name='apellidos']").val("");
                $("#contact_2021 input[name='email']").val("");
                $("#contact_2021 input[name='telefono']").val("");
                $("#contact_2021 input[name='city']").val("");
                $("#contact_2021 input[name='region']").val("");

                $("#contact_2021 textarea[name='comentario']").val("");
                $("#contact_2021 form button[type='submit']").prop('disabled', false);
                $("#contact_2021 input[type='checkbox']").prop('checked', false);

                $('#contact_2021 select[name="pais"]').find('option[value="0"]').attr('selected','selected');
                $('#contact_2021 select[name="pais"]').prop('selectedIndex',0);

                $('#contact_2021 select[name="departamento"]').find('option[value="0"]').attr('selected','selected');
                $('#contact_2021 select[name="departamento"]').prop('selectedIndex',0);

                $('#contact_2021 select[name="pais"]').select2('destroy');
                $('#contact_2021 select[name="pais"]').find("select option").eq(0).html();
                $('#contact_2021 select[name="pais"]').select2();

                $('#contact_2021 select[name="departamento"]').select2('destroy');
                $('#contact_2021 select[name="departamento"]').find("select option").eq(0).html();
                $('#contact_2021 select[name="departamento"]').select2();

                var locale = options.lang.toUpperCase();
                var eventName = locale + ' - Click - Contacto general';
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)
                [0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
                //fama.utils.debug('GoogleAnalyticsObject', eventName);
                ga('send', 'event', 'Contacto', 'Click', eventName);

            },
            error: (e) => {
                let errorMessage = (e.responseJSON) ? e.responseJSON.message : e.message
                this.utils.toast.log("Algo ha fallado. "+ errorMessage)
                fama.utils.debug(errorMessage)
            },
            complete: () => {
                this.changeButtonState('on')
            }
        });
    }

    /**
     * Change button state
     * @param {string} state on | off
     */
    changeButtonState(state) {
        let $button = $("#contact_2021 form button[type='submit']");
        switch(state) {
            case "on":
                $button.prop('disabled', false)
                $button.closest('.wrap-btnDefault').removeClass('loading');
                break

            case "off":
                $button.prop('disabled', true)
                $button.closest('.wrap-btnDefault').addClass('loading');
                break
        }
    }
}
