//isFama
//use pagination blog (fama)

'use strict'
import axios from 'axios'
/**
 * Blog
 * @author LoP
 * @version 1.0
 **/
export class BlogManager {
    /** Selectors **/
    $entryContainer     = $('#js-blog .js-blog-entry-container')
    $currentPage        = $('#js-blog .js-page-value')
    $currentPageID      = $('#js-blog .js-pageId-value')
    $currentLocale      = $('#js-blog .js-locale-value')
    $commentsLoader     = $('#js-blog .comment-loader')

    currentOffset       = 0;
    fin                 = false;

    /** Api Endpoints **/
    $loadMorePosts      = '/api/get-more-entries'

    constructor() {
        this.eventListeners()
    }

    eventListeners() {
        document.addEventListener('scroll', e => { this.onScroll(e) })
    }


    onScroll() {
        //if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
        let contenido=Math.round($('#js-blog .two-columns-wrap').outerHeight() + Math.round($('#js-blog .two-columns-wrap').offset().top));

        if (window.scrollY + window.innerHeight >= contenido && !this.fin) {
            this.fin=true;
            //fama.utils.debug('this.fin');
            let data = {
                pageId: this.$currentPageID[0].value,
                page: this.$currentPage[0].value,
                locale: this.$currentLocale[0].value,
            }
            if (this.currentOffset <= data.page) {
                this.$commentsLoader.show();
                axios
                    .post(this.$loadMorePosts, data)
                    .then( (response) => {
                        this.currentOffset = data.page;
                        let locale = this.$currentLocale[0];
                        response.data.blogItems.forEach(element => {
                        // TODO: Relacionar el local con la inserccion dinámica
                        let entry = `<div class="blog-entry">
                        <div class="blog-content">
                            <div class="aux-item">
                                <a href="${element.detailUrl}" class="img-link" >
                                    <div class="aux-img">
                                        <div class="wrap-img">
                                            <img src="${element.imagen}" loading="lazy" class="lazyload img" alt="${element.nombre_imagen}" title="${element.nombre_imagen}">
                                        </div>

                                        <div class="badge-blog">
                                            <p class="likes">
                                            <span class="likes-count">${element.likes_count}</span>`
                                            if (!document.cookie.includes(`liked_${element.id}_32`)) {
                                                entry += `<a href="#" class="like-button" data-item="${element.id}" data-type="32">
                                                            <i class="fa fa-heart-o heart heart--pink" aria-hidden="true"></i>
                                                        </a>`;
                                            }
                                            else {
                                                entry += `<a href="#" class="liked" data-item="${ element.id }" data-type="32">
                                                    <i class="fa fa-heart heart heart--pink" aria-hidden="true"></i>
                                                </a>`
                                            }

                                            entry +=`</p>
                                        </div>

                                    </div>
                                </a>
                            </div>

                            <div class="block-text text generic">`
                                var subs = element.descripcion.substring(0, 150);
                                entry +=`<span class="author a" href="${element.autorUrl}">${element.autor}</span>
                                <a href="${element.detailUrl}">
                                    <h2 class="block-title">${element.titulo}</h2>
                                </a>
                                <p class="desc">${subs}...</p>
                                <hr>
                            </div>
                        </div>
                    </div>`
                        ;

                        this.$entryContainer[0].innerHTML += entry;
                        });

                        this.$currentPage[0].value++;
                        this.$commentsLoader.hide();
                        this.fin = false;
                    })
                .catch( (error) => {
                    alert(error)
                    console.error(error)
                })
            }
        }

    }
}
