import Swiper from "swiper";
export class Instagram {

    /**
     *
     */
    constructor() {
        fama.utils.debug('Instagram loaded');
        this.init()
    }

    /**
     *
     */
    init() {
        //use home (fama)
        $(".instagram-widget").each( (i, item) => {
            let $item = $(item);
            var container = $item.find("#loader");
            var padre = $item.parent();
            if (container.length > 0) {
                $.ajax({
                    url: "/ajax.php?module=widgets&action=cargainsta",
                    data: null,
                    type: "get",
                    async: true,
                    dataType: "json",
                    success: (r) => {
                        if (r.status = "ok") {
                            $item.remove();
                            padre.append($(r.html));
                            this.loadSwiper();
                        }
                    }
                });
            } else {
                this.loadSwiper();
            }
        });
    }

    /**
     *
     */
    loadSwiper() {
        let pasadorHome = new Swiper(".instagram-widget .posts", {
            //direction: 'vertical',
            //paginationType: 'fraction',
            speed: 1000,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            //pagination: '.swiper-pagination',
            // noSwipingClass:"controls",
            slidesPerView: 1,
            preloadImages: false,
            lazyLoading: true,
            lazyLoadingInPrevNext: true,
            preventClicksPropagation: true
        });
    }
}
