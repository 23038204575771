import { FamaUtils } from "../../classes/FamaUtils";

export class FormContest {
    utils = window.fama.utils ?? FamaUtils.getInstance()
    constructor() {
        this.init()
    }

    init() {
        //fama.utils.debug('app.formConcurso_2021');
        $(".upload-form_2021").each( (i, item) => {
            var id = $(item);
            var mensaje = false;

            if (!app.utils.isLoad("select2", "$"))
                return;

            id.find("select[name='pais']").select2();

            $("button[type='submit']").on("click", (e) => {

                id.find("input[type=text]").each( (j, subItem) => {
                    $subItem = $(subItem)
                    if ($subItem .val() == '') {
                        e.preventDefault();
                        $subItem .css("border-color", "red");
                        mensaje = true;
                    }
                });

                if (!$.isNumeric($("select[name='pais']").val()) || $("select[name='pais']").val() <= 0) {
                    e.preventDefault();
                    $(".select2-selection").css("border-color", "red");
                    mensaje = true;
                }

                if (mensaje) {
                    this.utils.toast.log($("input[type='hidden'][name='campos_obligatorios']").val());
                }
            });

        });
    }
}
