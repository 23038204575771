export class CategoryListManager {
    constructor() {
        this.init()
    }

    /**
     *
     */
    init() {
        fama.utils.debug('CategoryListManager loaded');
        $('.catalogos-slider').on('init', (slick) => {
            let $slider = $(slick.currentTarget);

            $slider.addClass('isLoaded');
        });
        $('.catalogos-slider').slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: false,
            responsive: [
                {
                    breakpoint: 1080,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }

}
