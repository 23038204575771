/**
 * TODO: Hay que insertar los iframes dinámicamente para evitar la carga innecesaria de recursos
 */
export class VideoSlider {

    currentVideoPlayer

    constructor() {
        fama.utils.debug('VideoSlider loaded')
        this.eventListeners()
    }

    eventListeners () {
        $('.js-play').on('click', (e) => {this.playSliderVideo(e)})
    }

    /**
     * Play video in other pages than "videos" (Home & Empresa)
     * @param {jqueryEvent} ev
     */
    playSliderVideo(ev) {
        //fama.utils.debug('videos_2021.video.play');
        let $target = $(ev.currentTarget);
        let $iframe = $target.closest('.videos-section-item').find('iframe');
        let $videoContainer = $target.parent().find('.video-container')
        let $videoMetaData = $target.parent().find('.js-videoMetaData')
        let videoPlayer = this.currentVideoPlayer



        //different video from the one load
        if(this.currentVideoPlayer && this.currentVideoPlayer.element != $iframe.get(0)) {
            this.currentVideoPlayer.off('play')
            this.currentVideoPlayer.off('pause')
            this.currentVideoPlayer.off('timeupdate')
            this.currentVideoPlayer.unload()
            this.currentVideoPlayer.destroy()
            $videoContainer.append(this.buildIFrame($videoMetaData))
            $iframe = $videoContainer.find('iframe')
            videoPlayer = new Vimeo.Player($iframe);
            // this.currentVideoPlayer.destroy()
        }
        //initial load (no video yet)
        else if(!this.currentVideoPlayer){
            $videoContainer.append(this.buildIFrame($videoMetaData))
            $iframe = $videoContainer.find('iframe')
            videoPlayer = new Vimeo.Player($iframe);
        }

        this.currentVideoPlayer = videoPlayer
        videoPlayer.play();

        if(videoPlayer !== undefined){
            videoPlayer.on('play', function(){
                videoPlayer.on('timeupdate', function(data) {
                    let progress = data.percent * 100;
                    if($('.slider-videos-home').length > 0){

                        let progressBar=$target.closest('.videos-section-item').find('.progress');
                        progressBar.css('background-size', progress + '% 100%').attr('aria-valuenow', progress );
                    }

                });
            })

            videoPlayer.on('pause', () => {
                $('.js-play').removeClass('isPlayd')
            })
        }

        $target.closest('.videos-section-item').find('img.isSee').removeClass('isSee');
        $target.addClass('isPlayd');
        $target.siblings('.js-pause').removeClass('isStoped');
        $target.siblings('.js-pause').hide();
    }

    buildIFrame($metadata){
        let i = `<iframe id="video-${$metadata.data('video-id')}"
        data-video-order=""
        loading="lazy"
        data-video-id="${ $metadata.data('video-id')}"
        title="${$metadata.data('video-title')}"
        data-video-title="${$metadata.data('video-title')}"
        data-src="${ $metadata.data('src') }"
        src="${ $metadata.data('src')}"
        frameborder="0"
        allow="autoplay;
        fullscreen;
        picture-in-picture"></iframe>`
        return i
    }
}
