export class Animatable {

    animatableElements     = $('.js-animatable')

    constructor() {
        fama.utils.debug('Animatable loaded')
        this.eventListeners()
    }

    eventListeners(){
        $(window).on('scroll', (e) => { this._onScroll() });
    }

    /**
     * Handles scroll and fade-in with transition animatable elements when visible
     */
    _onScroll() {
        let windowHeight = window.innerHeight;
        let animatableElements = this.animatableElements;

        // Show normal animatable elements
        for (let i = 0; i < animatableElements.length; i++) {
            let positionFromTop = animatableElements[i].getBoundingClientRect().top;
            let delay = $(animatableElements[i]).hasClass('js-animatableInstant') ? 0 : 600;
            if (positionFromTop - windowHeight <= 0) {
                setTimeout(function () {
                    $(animatableElements[i]).addClass('isVisible');
                }, i * 100 + delay);
            }
        }
    }
}
