import { FamaUtils } from "./FamaUtils";

export class ProductDetail {

    static instance
    static getInstance() {
        if (!ProductDetail.instance)
        {
            ProductDetail.instance = new ProductDetail();
        }
        return ProductDetail.instance;
    }

    utils = window.fama.utils || FamaUtils.getInstance()
    $topbarHeight;

    constructor() {
        fama.utils.debug('ProductDetail loaded')
        this.$topbarHeight = $('.topbar').height()
        this.eventListeners()
        this.init()
    }

    init() {
        this.initSliders();

        // Select first item from sidebar
        let firstItemSidebar = $('.sidebar').find('>.item').length > 0 ? $('.sidebar').find('>.item')[0] : null;

        $(firstItemSidebar).find('a').trigger('click');
    }

    eventListeners() {
        const self = this;
        $('.control-fullscreen-clientphotos').off('click').on('click', (e) => { this.showClientPhotosFullScreen(e) });
        $('.close-fullscreen-clientphotos').off('click').on('click', (e) => { this.hideClientPhotosFullScreen(e) });

        $('.module-tabs-nav > .item').off('click').on('click', (e) => { this.changeNavTab(e) });
        $('.modules-menu .module .module-header').off('click').on('click', (e) => { this.showSectionDetail(e) });
        $('.header-box-content').off('click').on('click', '.valorations', (e) => { this.goToValorations(e) });
        $('.control-group-prev-next').off('mouseover').on('mouseover', (e) => { this.hideControls(e) });
        $('.control-group-prev-next').off('mouseleave').on('mouseleave', (e) => { this.showControls(e) });
        $('.control-readmore').off('click').on('click', (e) => { this.scrollReadMore(e) });

        $('.control-prev').off('click').on('click', (e) => {
            let $slider = $(e.currentTarget).closest('.slider-controls').siblings('.slider.slick-initialized')
            $slider.slick('slickPrev');
            // $('.slider').slick('slickPrev');
        });
        $('.control-next').off('click').on('click', (e) => {
            let $slider = $(e.currentTarget).closest('.slider-controls').siblings('.slider.slick-initialized')
            $slider.slick('slickNext')
            // $('.slider').slick('slickNext');
        });

        $('.js-open360').off('click').on('click', (e) => { this.open360() });
        $('.js-close360').off('click').on('click', (e) => { this.close360() });
        $(document).on('keyup', (e) => { this.moveSlider(e); });
        $('.js-downloadImage').on('click', (e) => { this.downloadImage(e) });
        $('.form-contact-product').on('submit', (e) => { this.contactFormDetail(e) });
        $(".show_price").on('click', (e) => {this.showPrice(e)});
        $('.module-valoraciones').off('click').on('click', '.tr', (e) => { this.openValoration(e) });
        $('.js-seeMoreValorations').on('click', (e) => { this.seeMoreValorations(e) });
        $('.js-selectGbRegion').on('click', (e) => {
            const region = $('input[name="gb-region"]:checked').val();

            self.selectGbRegion(region)
        });
        $('.js-selectGbRegionXs').on('click', (e) =>{
            const region = $('input[name="gb-region-xs"]:checked').val();

            self.selectGbRegion(region)
        });
    }

    initSliders() {
        // TODO Check if is in product details, if not, please dont run this (all sliders will be initialized...)
        let isInProductsPage = ($('.app-detail').length>0 || $('.famaliving-detail').length>0);
        if($('.slider:not(.slick-initialized)').length>0 && isInProductsPage){
            fama.utils.debug('productDetail initSliders');
            this.initSlider1();
        }
    }

    //famliving, fama products page
    initSlider1() {
        if($('.slider').length>0){
            fama.utils.debug('productDetail initSliders1');
            $('.slider:not(.slick-initialized)').slick({
                lazyLoad: 'ondemand',
                autoplaySpeed: 3000,
                dots: true,
                infinite: true,
                speed: 800,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                center: true,
                arrows: false,
                fade: true,
                cssEase: 'linear',
            });
        }

    }

    /**
     * Initialize (CustomerImages) inside of a module (Left menu element) in Product Detail
     * @param {jQuery Element} $module
     */
    initModuleSlider($module) {
        fama.utils.debug('productDetail initModuleSlider');
        let slider2 = $module.find('.slider2');
        let slider2Thumb = $module.find('.slider2-thumb');
        let slider2Fullscreen = $module.find('.slider2-fullscreen');

        if (!$(slider2).hasClass('slick-initialized')) {
            //$(slider2).addClass('isInitialized');

            // On slick init
            $(slider2).on('init', (slick) => {
                let $slider = $(slick.currentTarget);
                $slider.addClass('isInitialized');
            });


            // on slick before Change
            $(slider2).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
                this.getInfoImage(slick, currentSlide, nextSlide);
            });

            $(slider2).slick({
                lazyLoad: 'ondemand',
                infinite: true,
                speed: 800,
                slidesToShow: 1,
                slidesToScroll: 1,
                center: true,
                arrows: true,
                fade: true,
                cssEase: 'linear',
                asNavFor: !this.utils.isMobile() ? $module.find('.slider2-thumb, .slider2-fullscreen') : $module.find('.slider2-fullscreen'),
            });



    //famaliving,fama detail product
            if (!this.utils.isMobile()) {
                $(slider2Thumb).on('init', (slick) => {
                    let $slider = $(slick.currentTarget);
                    $slider.addClass('isInitialized');
                });
                $(slider2Thumb).slick({
                    lazyLoad: 'ondemand',
                    infinite: true,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    asNavFor: $module.find('.slider2, .slider2-fullscreen'),
                    centerMode: true,
                    focusOnSelect: true,
                    draggable: false,
                    arrows: false,
                    fade: false,
                    cssEase: 'linear',
                });
                $(slider2Fullscreen).slick({
                    lazyLoad: 'ondemand',
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    asNavFor: $module.find('.slider2, .slider2-thumb'),
                    draggable: false,
                    arrows: true,
                    fade: false,
                    cssEase: 'linear',
                    prevArrow: $(slider2Fullscreen).siblings('.prev'),
                    nextArrow: $(slider2Fullscreen).siblings('.next')
                });
            }else{
                $(slider2Fullscreen).slick({
                    lazyLoad: 'ondemand',
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    asNavFor: $module.find('.slider2'),
                    draggable: false,
                    arrows: true,
                    fade: false,
                    cssEase: 'linear',
                    prevArrow: $(slider2Fullscreen).siblings('.prev'),
                    nextArrow: $(slider2Fullscreen).siblings('.next')
                });
            }
        }
        // setTimeout(() => {
        //     $(window).trigger('resize')

        // }, 1000)
    }

    /**
     * 360 Module
     * @param {*} $module
     */
    init360($module) {
        fama.utils.debug('init360');
        let $360inline = $module.find('#e360');
        var $360 = $module.find("#e360-popup");

        // Load 360 iframes if there are any
        if ($360inline.length > 0 && !$($360inline).hasClass('is-initialized')){
            var src = $360.data("src");
            var left = 0;
            var top = 0;
            var width = $(document).width();

            top = ($(window).height() * 10) / -100;

            if (width > 1500) {
                left = Math.ceil((width - 1500) / 2);
            }

            $(window).on("resize",  (e) => {
                var top = ($(window).height() * 10) / -100;
                var left = 0;
                var width = $(document).width();
                if (width > 1500) {
                    left = Math.ceil((width - 1500) / 2);
                }

                src += "&toph=" + top + "&leftw=" + left + "&maxwidth=" + $(document).width();
                $360.attr("src", src);
            });

            src += "&toph=" + top + "&leftw=" + left + "&maxwidth=" + $(document).width();
            $360.attr("src", src);
            $360inline.attr("src", src);
            $360inline.addClass('is-initialized');
        }
    }

    /**
     * Set src to images in any module
     * @param {*} $module
     */
    initGallery($module){
        let $gallery = $module.find('.module-gallery');

        if($gallery.length){
            fama.utils.debug('initGallery');
            let $imgs = $gallery.find('img');
            $imgs.each(function(){
                let $img = $(this);
                if(!$img.attr('src'))
                    $img.attr('src', $img.attr('data-src'));
            });
        }
    }

    refreshModuleContent($module) {
        this.init360($module);
        this.initGallery($module);
        this.initModuleSlider($module);
    }

    getInfoImage(slick, currentSlide, nextSlide) {
        fama.utils.debug('getInfoImage');
        let galleryElem = $(slick.$slider).closest('.module-gallery');
        let nameElem = $(galleryElem).find('.js-clientPhotoName');
        let placeElem = $(galleryElem).find('.js-clientPhotoPlace');
        let indexElem = $(galleryElem).find('.module-gallery-current-index');
        let nextSlideElem = $(slick.$slider).find('.slick-slide[data-slick-index="'+nextSlide+'"]').find('.item');
        let nextSlideName = $(nextSlideElem).attr('data-name');
        let nextSlidePlace = $(nextSlideElem).attr('data-locality');

        $(nameElem).html(nextSlideName);
        $(placeElem).html(nextSlidePlace);
        $(indexElem).html(nextSlide + 1);
    }

    scrollReadMore(e) {
        fama.utils.debug('scrollReadMore');
        let moreInfoPos = $(window).height() - this.$topbarHeight;

        $('html, body').animate(
            { scrollTop: moreInfoPos },
            500,
            'swing'
        );
    }

    hideControls(e) {
        let $e = $(e)
        fama.utils.debug('hideControls');
        if(this.utils.isMobile()){
            return false;
        }
        if(!$('.header-detail').hasClass('is-fullscreen') && !$e.hasClass('noSpecialBehaviour')) {
            $('.app').addClass('hideControls');
        }
    }

    showControls(e) {
        let $e = $(e)
        if(!$('.header-detail').hasClass('is-fullscreen') && !$e.hasClass('noSpecialBehaviour')) {
            $('.app').removeClass('hideControls');
        }
    }

    goToValorations(e) {
        let valorationsPos = $('.module-valoraciones').offset().top - this.$topbarHeight;

        $('html, body').animate(
            { scrollTop: valorationsPos },
            500,
            'swing'
        );
    }

    showSectionDetail(e) {
        // Work only for sm devices
        if (this.utils.isMobile()) {
            let $module = $(e.currentTarget).closest('.module');
            let moduleID = $module.attr('id');
            let closeOpenModule = $module.hasClass('is-open');

            $('.modules-menu .module').each(function (index, aModule) {
                if ($(aModule).attr('id') != moduleID) {
                    $(aModule).find('.module-body').slideUp(0);
                    $(aModule).removeClass('is-open');
                }
            });

            // Initialize slider if exists or 360 iframes
            this.refreshModuleContent($module);

            $module.toggleClass('is-open');
            if ($module.hasClass('is-open')) {
                let modulePos = $module.offset().top - this.$topbarHeight;
                $('html, body').animate(
                    { scrollTop: modulePos },
                    0,
                    'swing'
                );
                $module.find('.module-body').slideDown(0);
            } else {
                $module.find('.module-body').slideUp(0);
            }
        }
    }

    showClientPhotosFullScreen(e) {
        let $e = $(e.currentTarget)
        fama.utils.debug('showClientPhotosFullScreen not fama');
        let fullscreenModal = $e.closest('.module').find('.client-photos-modal');

        $(fullscreenModal).addClass('isVisible');
        $('body').addClass('detail');
        $('body').addClass('stopScroll');
    }

    hideClientPhotosFullScreen(e) {
        let $e = $(e.currentTarget)
        let fullscreenModal = $e.closest('.client-photos-modal');

        $(fullscreenModal).removeClass('isVisible');
        $('body').removeClass('detail');
        $('body').removeClass('stopScroll');
    }

    changeNavTab(e) {
        let $e = $(e.currentTarget);
        let tab = $e.attr('data-tab');
        let $module = $e.closest('.module-tabs');
        let $tab = $module.find('.module-tab[data-tab="'+tab+'"]');
        if($tab.length > 0){
            $module.find('.module-tabs-nav .item.is-active').removeClass('is-active');
            $e.addClass('is-active');
            let $oldModule = $module.find('.module-tab:visible');
            $oldModule.fadeOut(200, function(){
                $oldModule.removeClass('isActive');
                $tab.fadeIn();
                $tab.addClass('isActive');
            });
        }
    }
    open360() {
        $('.popup-360').addClass('isVisible');
    }

    close360() {
        $('.popup-360').removeClass('isVisible');
    }

    moveSlider(e) {
        if (e.which === 37 || e.which === 39) {
            let activeSlide = $('.sidebar').find('.item.is-active a').attr('href');
            let activeModuleSlider = $('.slider-container').find('.module'+activeSlide).find('.slick-slider');
            let slider = activeModuleSlider.length > 0 ? activeModuleSlider : $('.header-detail').find('.slick-slider');
            let moveDirection = e.which === 37 ? 'left' : 'right';

            if (slider) {
                if (moveDirection === 'left') $(slider).slick('slickPrev');
                else $(slider).slick('slickNext');
            }
        }
    }

    downloadImage(e) {
        $(e.currentTarget).addClass('downloaded');
    }

    contactFormDetail(e) {
        let $e = $(e.currentTarget)
        e.preventDefault();
        let options = $e.serialize();
        let $form = $e;
        $form.removeClass('isSent');

        $.ajax({
            url: "/ajax.php?module=productos&action=contact&language="+$('html').attr('lang'),
            data: options,
            type: "post",
            async: true,
            dataType: "json",
            beforeSend: function () {
                fama.utils.debug('aqui...');
                $('.form-contact-product button[type=submit]').attr('disabled', true);
                $form.fadeOut('slow');
            },
            success: function (r) {
                if (r.status == 'ok') {
                    $form.html("<label>" + r.message + "</label>").fadeIn('slow');
                    $form.addClass('isSent');

                    let locale = $('html').attr('lang').toUpperCase();
                    let productName = $form.data('name');
                    if(productName === undefined) {
                        productName = 'general';
                    } else {
                        productName = '- ' + productName;
                    }
                    let eventName = locale + ' - Click - Contacto ' + productName;
                    let gtagCode = $form.data('gtag');

                    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                        m=s.getElementsByTagName(o)
                        [0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

                    ga('gtag_' + gtagCode + '.send', 'event', 'Contacto', 'Click', eventName);
                } else {
                    $form.html("<label><h2>Ha ocurrido un error, intentelo más tarde</h2></label>").fadeIn('slow');

                }
            },
            error: function(r){
                $form.html("<label><h2>Ha ocurrido un error, intentelo más tarde</h2></label>").fadeIn('slow');
            }
        });

        return false;
    }

    showPrice() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                $.ajax({
                    url: "/ajax.php?module=geoIP&action=locate",
                    data: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    type: "post",
                    async: true,
                    dataType: "json",
                    success: function (r) {
                        if (r.html !== 'hide') {
                            $('.box-check-price').fadeOut(400, function(){
                                $('.box-price').removeClass('d-none').fadeIn(400);
                            })
                        } else {
                            $('.box-check-price').fadeOut(400);
                            $('.box-price').hide();
                        }
                    }
                });
            }, function(e) {
                if (e.code === e.PERMISSION_DENIED) {
                    alert($('#i18n_locate_blocked').html());
                }
            });
        }
    }

    openValoration(e) {
        let $e = $(e.currentTarget)
        let target = $e.attr('data-label');

        if (this.utils.isMobile()) {
            if ($e.hasClass('collapse')) { // Close
                $('body').css('overflowY', 'auto');
                $('#rates-lateral').find('.tr').removeClass('collapse').addClass('collapsed');
                $e.removeClass('collapse').addClass('collapsed');
                $('#rates-lateral').find('.list-rates').removeClass('show');
            } else { // Open
                $('body').css('overflowY', 'hidden');
                $('#rates-lateral').find('.tr').removeClass('collapse').addClass('collapsed');
                $e.removeClass('collapsed').addClass('collapse');
                $('#rates-lateral').find('.list-rates').removeClass('show');
                $('#rates-lateral').find('.list-rates[data-target="'+target+'"]').addClass('show');
            }
        } else {
            $('#rates-lateral').find('.tr').removeClass('isSelected');
            $e.addClass('isSelected');
            $('.desktop-valoration-opinions').find('.list-rates').removeClass('isVisible');
            $('.desktop-valoration-opinions').find('.list-rates[data-target="'+target+'"]').addClass('isVisible');
        }
    }

    seeMoreValorations(e) {
        let $element = $(e.currentTarget)
        let start = $element.data('start');
        let total = $element.data('total');
        let list = $element.data('list');

        for(let i = start; i <= (start + 4) && i <= total; i ++){
            $('[data-count="'+i+'"]').filter('[data-list="'+list+'"]').removeClass('isHidden');
            if(i === total){
                $element.closest('.list-rates').addClass('seeMore');
            }
        }
        if(start <= total){
            $element.attr('data-start', (start + 5));
            $element.data('start', (start + 5));
        }
    }

    selectGbRegion(region) {
        $.ajax({
            url: "/ajax.php?module=productos&action=selectGbRegion&language=" + $('html').attr('lang'),
            data: {
                region: region
            },
            type: "post",
            async: true,
            dataType: "json",
            beforeSend: function () {
                $('input[name="gb-region-xs"]').attr('disabled', true);
                $('input[name="gb-region"]').attr('disabled', true);
                $('.js-selectGbRegion').prop('disabled', true);
                $('.js-selectGbRegionXs').prop('disabled', true);
            },
            success: function (r) {
                location.reload();
            },
        });
    }
}
