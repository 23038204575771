import {Draggable} from "gsap/Draggable";


// use SOLO FAMA

//fama_new_2021
let utils_2021;
var lastScrollTop=0;
var st;
var baja;
utils_2021 = {};

utils_2021.common = {
    //use (fama)-general
    inMobile() {
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()) || $('body').outerWidth() <= 790;
    },
    dirScroll(){

        st = window.pageYOffset || document.documentElement.scrollTop;
        var inMobile = parseFloat($('body').css('width')) <= 991;
        if (st > lastScrollTop){
            baja=true;
            if(inMobile){
                $('.stopScroll .menu-vertical.menu-vertical-2022').css({height: 'calc(100vh - 3rem)'});
            }

        } else {
            baja=false;
            if(inMobile){


            $('.stopScroll .menu-vertical.menu-vertical-2022').css({height: 'calc(100vh - 9rem)'});
            }

        }
        lastScrollTop = st;

        return baja;

    },
    hightMenuMvl(){
        utils_2021.common.dirScroll();
    }

},

utils_2021.layout={
    changeMenuEntry(){
        //use HOME (fama)-cambio categorias con moseover
        clearInterval();
        let category=$(this).data('id');
        let categorySelected;
        $(this).closest('.menu-list').find('.list-item').each(function(){
            $(this).removeClass('isActive');
        });
        $(this).addClass('isActive');
        $('.container-collage').removeClass('isActive');

        categorySelected=$('.container-collage .collage[data-id="'+category+'"]');
        categorySelected.closest('.container-collage').addClass('isActive');
    },
    binds(){
        $('.js-change-menuEntry').on('mouseover', this.changeMenuEntry);
    },

},

utils_2021.simulators_layout={
    changeMenuSimulator(){
        //use SIMULATOR OF SOFAS (fama)
        let simulator=$(this).data('id');
        let simulatorSelected;
        $(this).closest('.menu-list').find('.list-item').each(function(){
            $(this).removeClass('isActive');
        });
        $(this).addClass('isActive');
        $('.simulator-entry').removeClass('isActive');

        simulatorSelected=$('.simulator-entry .simulator[data-id="'+simulator+'"]');
        simulatorSelected.closest('.simulator-entry').addClass('isActive');

    },
    binds(){
        $('.js-change-menuSimulator').on('click', this.changeMenuSimulator);
    },

},

// ****************************** MENU (NAV) **************************//
// utils_2021.nav_horizontal={
//     //use all (fama)
//     init(){
//         var hayActive=false;
//         if($('.menu-horizontal').length > 0){
//             $('.menu-horizontal').find('.item').each(function(){
//                 if($(this).hasClass('is-active')){
//                     $(this).removeClass('no-active');
//                     hayActive=true;
//                 }else{
//                     if($(this).find('.logo').length > 0){
//                         $(this).removeClass('no-active');
//                     }else{
//                         $(this).addClass('no-active');
//                     }
//                 }
//             })

//             if(!hayActive){
//                 $('.menu-horizontal').find('.item').each(function(){
//                     $(this).removeClass('no-active');
//                 })
//             }

//         }
//     }
// }


// ****************************** SLIDER logos (History) **************************//
// OLD ?? History Block. USED??
utils_2021.dragLogos={
    //use empresa (fama)
    init(){
        var a = $('.box-logos');
        var t = $('.modal-drag-hitzone');

        var s = 0;
        //var r = $(".dash-line").width() / 24;
        var l = 0;
        var d = 0;
        $(".slider-logos .item-logo").each(function(e) {
            var a = $(this);
            s += a.width();
        }),

            setTimeout(function () {
                $(".slider-logos").css({
                    width: s + "px"
                });
            }, 200)

        setTimeout(function () {
            let w = $(".slider-logos").outerWidth();

            Draggable.create(".handler", {
                type:"x",
                //edgeResistance:0.65,
                bounds:".container-drag",
                inertia:true,
                onDragStart: function() {},
                onDragEnd: function() {},
                onDrag: function() {
                    var e, a;
                    a = (e = this).endX * w / e.maxX,
                        TweenMax.to($(".wrap-logos .slider-logos"), 2, {
                            x: -a,
                            ease: Power4.easeOut
                        })
                }
            });
        }, 1500)
    }

},
    // ******************************/ SLIDER logos (History) **************************//
utils_2021.sliderHome={
//use home (fama)
    init(){
// ****************************** SLIDER HOME primary **************************//
        if($('.slider-home').length > 0){
            $('.slider-home').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                // initialSlide: 2,
                dots: false,
                speed: 3000,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                //centerPadding: '3.5rem',
                arrows: true,
                autoplay: true,
                //variableWidth: true,
                //pauseOnFocus: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            swipeToSlide: true,
                            arrows: true,
                            centerMode: true,
                            //centerPadding: '4rem',
                            slidesToShow: 1
                        }
                    },

                ]
            })


            $('.slider-home').on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');

                let numActive= slider.find('.slick-slide.slick-current').data('num-index');

                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

            });
        }
// ****************************** END  SLIDER HOME primary **************************//

// ****************************** SLIDER HOME simulation**************************//
/*
        if($('.slider-home-simulations-list').length>0){
            $('.slider-home-simulations-list').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: false,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                focusOnSelect: true,
                arrows: true,

                draggable: false,
                touchMove: false,
                swipeToSlide: false,
                swipe: false,
                //variableWidth: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            swipeToSlide: true,
                            arrows: true,
                            centerMode: true,
                            //centerPadding: '4rem',
                            slidesToShow: 1,
                            draggable: true,
                            touchMove: true,
                            swipe: true,
                        }
                    },

                ]
            });
        }

        // *** slider of typs of simulations ***
        if($('.slider-type-simulation').length>0){

            // my slick slider options
            const options = {
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                dots: true,
                speed: 1000,
                slidesToScroll: 1,

                swipeToSlide: false,
                draggable: false,
                touchMove: false,
                swipe: false,

                focusOnSelect: true,
                arrows: false,
                autoplay: true,
                //variableWidth: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,

                            draggable: true,
                            touchMove: true,
                            swipe: true,
                        }
                    },

                ]
            };

            utils_2021.fadeTextTranslate.init();

            // my slick slider as const object
            const mySlider = $('.slider-type-simulation').on('init', function(slick) {

                // set this slider as const for use in set time out
                const slider = this;

                // slight delay so init completes render
                setTimeout(function() {
                    console.log('aaaaaa');
                    // dot buttons
                    let dots = $('.slick-dots > li > button', slider);
                    let num=0;
                    //each dot button function
                    $.each(dots, function(i,e) {

                        // slide id
                        let slide_id = $(this).attr('aria-controls');

                        // custom dot image
                        let dot_img = $('#'+slide_id).data('dot-img');
                        $(this).attr('data-dot-num', num);
                        num++;
                        $(this).html('<img src="' + dot_img + '" alt="" />');

                    });



                }, 100);


            }).slick(options);

            $('.slider-type-simulation').each(function(){
                $(this).on('afterChange', function (){

                    let slider= $(this);
                    let numActive= slider.find('.slick-dots .slick-active button').data('dot-num');

                    slider.closest('.wrap-slider-simulation-item').find('.scrollbar-item.isActive').removeClass('isActive');
                    slider.closest('.wrap-slider-simulation-item').find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

                })
            })





        }
*/
// ****************************** END  SLIDER HOME simulation**************************//

// ****************************** SLIDER HOME concurso**************************//

        if($('.slider-home-concurso').length > 0){
            $('.slider-home-concurso').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                initialSlide: 2,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                variableWidth: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            swipeToSlide: true,
                            centerMode: true,
                            slidesToShow: 1
                        }
                    },

                ]
            })


            $('.slider-home-concurso').on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');

                let numActive= slider.find('.slick-slide.slick-current').data('num-index');

                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

            });

        }

// ****************************** END SLIDER HOME concurso **************************//

// ****************************** SLIDER HOME NON-STOP **************************//
        if($('.home-non-stop .carousel-container').length>0){
            $('.home-non-stop .carousel-container').slick({
                centerMode: false,
                //slidesToShow: 3,
                infinite: false,
                initialSlide: 0,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                variableWidth: true,
                arrows: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            //slidesToShow: 1
                        }
                    },

                ]
            })
        }

        // ****************************** SLIDER HOME EMPRESA MVL **************************//
        if($('.home-empresa.mvl .empresa-mvl .carousel-container').length>0){
            $('.home-empresa.mvl .empresa-mvl .carousel-container').slick({
                centerMode: false,
                //slidesToShow: 3,
                infinite: false,
                initialSlide: 0,
                dots: false,
                speed: 500,
                //slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                variableWidth: true,
                arrows: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            //slidesToShow: 1
                        }
                    },

                ]
            })
        }

// ****************************** END SLIDER HOME EMPRESA MVL **************************//

// ****************************** SLIDER HOME videos **************************//
        if($('.slider-videos-home').length > 0){
            $('.slider-videos-home').slick({
                centerMode: true,
                slidesToShow: 1,
                //infinite: true,
                initialSlide: 0,
                dots: true,
                customPaging : function(slider, i) {
                    var thumb = $(slider.$slides[i]).data();
                    return '<a class="dot" style="height: 100%; width: 100%">'+(i+1)+'</a>';
                },
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                //centerPadding: '3.5rem',
                arrows: true,
                lazyLoad: 'ondemand',
                //variableWidth: true,
                //pauseOnFocus: true,
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            //centerPadding: '4rem',
                            slidesToShow: 1
                        }
                    },
                ]
            })

            var $slider = $('.slider-videos-home');


            if($('.slider-videos-home .slick-dots').length > 0){

                var widthNumPage=0;
                $slider.find('.slick-dots li').each(function(){
                    widthNumPage=widthNumPage + $(this).width();

                })

                $slider.find('.wrap-progress .progress').css('width', 'calc(100% - ' + widthNumPage +'px)');
            }



            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                $slider.find('.slick-current').find('.wrap-progress .progress').hide();
            });
            $slider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
                $slider.find('.slick-current').find('.wrap-progress .progress').show();

            });

        }
// ****************************** END  SLIDER HOME videos**************************//

// ****************************** SLIDER empresa **************************//
        if($('.slider-empresa').length > 0){
            $('.slider-empresa').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                initialSlide: 2,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                variableWidth: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            variableWidth: false,
                        }
                    },

                ]
            })
        }
// ****************************** SLIDER empresa **************************//

// ****************************** SLIDER empresa-design **************************//
        if($('.slider-design').length > 0){
            $('.slider-design').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                //initialSlide: 1,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                autoplay: true,
                lazyLoad: 'ondemand',
                //variableWidth: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            variableWidth: false,
                        }
                    },

                ]
            })

            $('.slider-design').on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');

                let numActive= slider.find('.slick-slide.slick-current').data('num-index');

                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

            });
        }
// ****************************** SLIDER empresa-design **************************//
// ****************************** SLIDER empresa-international **************************//
        if($('.slider-international').length > 0){
            $('.slider-international').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                //initialSlide: 1,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                autoplay: true,
                lazyLoad: 'ondemand',
                //variableWidth: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            variableWidth: false,
                        }
                    },

                ]
            })

            $('.slider-international').on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');

                let numActive= slider.find('.slick-slide.slick-current').data('num-index');

                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

            });
        }
// ******************************/ SLIDER international **************************//

// ****************************** SLIDER empresa-international **************************//
        if($('.slider-industry').length > 0){
            $('.slider-industry').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: true,
                //initialSlide: 1,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                lazyLoad: 'ondemand',
                //autoplay: true,
                //variableWidth: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: true,
                            arrows: false,
                            centerMode: true,
                            slidesToShow: 1,
                            variableWidth: false,
                        }
                    },

                ]
            })

            $('.slider-industry').on('afterChange', function (){
                let slider= $(this).closest('.js-slider-wrap');

                let numActive= slider.find('.slick-slide.slick-current').data('num-index');

                slider.find('.scrollbar-item.isActive').removeClass('isActive');
                slider.find('.scrollbar-home .scrollbar-item[data-num="'+numActive+'"]').addClass('isActive');

            });
        }
// ******************************/ SLIDER international **************************//

// ****************************** SLIDER empresa-madeInSpain **************************//
        if($('.slider-madeInSpain').length > 0){
            let num=$('.slider-madeInSpain').data('num');
            if(num < 10){
                num='0'+num;
            }
            $('.slider-madeInSpain').slick({
                centerMode: false,
                slidesToShow: 1,
                infinite: false,
                initialSlide: 0,
                dots: true,
                customPaging : function(slider, i) {
                    var thumb = $(slider.$slides[i]).data();
                    let numItem=i+1;
                    if(numItem < 10){
                        numItem='0'+numItem;
                    }
                    return '<a class="dot">'+'<span>'+numItem+' / '+num+'</span>'+'</a>';
                },
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                //autoplay: true,
                variableWidth: true,
                // responsive: [
                //     {
                //         breakpoint: 991,
                //         settings: {
                //         swipeToSlide: true,
                //         arrows: false,
                //         centerMode: true,
                //         slidesToShow: 1,
                //         variableWidth: true,
                //         }
                //     },

                // ]
            })

            var $slider = $('.slider-madeInSpain');
            var $progressBar = $('.progress');

            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
                $progressBar
                    .css('background-size', calc + '% 100%')
                    .attr('aria-valuenow', calc );

            });

        }
// ******************************/ SLIDER madeInSpain **************************//

// ****************************** SLIDER famalovres **************************//
        if($('.slider-famalovers').length > 0){
            $('.slider-famalovers').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                autoplay: false,
                lazyLoad: 'ondemand',
                //variableWidth: true,
            })
        }

// ****************************** SLIDER slider-trabajaWithUs **************************//
        if($('.slider-workWithUs').length > 0){
            $('.slider-workWithUs').slick({
                centerMode: true,
                slidesToShow: 1,
                infinite: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                autoplay: false,
                adaptiveHeight: true,
                lazyLoad: 'ondemand',
            })
        }
// ****************************** SLIDER slider-ourTours **************************//
        if($('.slider-ourTours').length > 0){
            var $slider = $('.slider-ourTours');

            $slider.on('init', function(event, slick, currentSlide, nextSlide) {
                var maxHeight = -1;
                $('.slider-ourTours.slick-initialized .slick-list').each(function() {
                    Math.ceil($(this).height());
                });
                $('.slider-ourTours .slick-slide').each(function() {
                    if (Math.ceil($(this).height()) >= maxHeight) {
                        maxHeight = Math.ceil($(this).height());
                    }
                });
                $('.slider-ourTours .slick-slide').each(function() {
                    if (Math.ceil($(this).height()) === maxHeight) {
                        $(this).css('padding-top', 7 +'px');
                    }
                });
                $('.slider-ourTours .slick-slide').each(function() {
                    if (Math.ceil($(this).height()) < maxHeight) {
                        $(this).css('margin', Math.ceil((maxHeight-Math.ceil($(this).height()))/2 ) + 'px 0');
                    }
                });
            });


            $('.slider-ourTours').slick({
                slidesToShow: 1,
                initialSlide: 0,
                dots: false,
                infinite:false,
                speed: 500,
                //draggable: true,
                swipe:true,
                //centerMode: true,
                useTransform:true,
                cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                //slidesToScroll: 1,
                //swipeToSlide: true,
                arrows: true,
                autoplay: false,
                adaptiveHeight: true,
                vertical: true,
                verticalSwiping:true,
                prevArrow: '<button class="slide-arrow prev-arrow custom"><div class="prev"></div></button>',
                nextArrow: '<button class="slide-arrow next-arrow custom"><div class="next"></div></button>',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            swipeToSlide: false,
                            swipe: false,
                            draggable: false,
                        }
                    },

                ]

            });

        }

// ****************************** SLIDER slider-enviroment **************************//
        if($('.slider-enviroment').length > 0){

            $('.slider-enviroment').slick({
                centerMode: true,
                slidesToShow: 1,
                initialSlide: 0,
                dots: true,
                customPaging : function(slider, i) {
                    var thumb = $(slider.$slides[i]).data();
                    return '<a class="dot" style="height: 100%; width: 100%">'+(i+1)+'</a>';
                },
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: false,
                autoplay: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 975,
                        settings: {
                            arrows: false,
                        }
                    },
                ]
            })


            var $slider = $('.slider-enviroment');
            var $progressBar = $('.wrap-slider-enviroment .progress');
            //utils_2021.empresaEnviroment.multiSlideAdaptiveHeight($slider);

            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
                $progressBar
                    .css('background-size', calc + '% 100%')
                    .attr('aria-valuenow', calc );

                $(this).find('.slick-list').height('auto');


                // utils_2021.empresaEnviroment.multiSlideAdaptiveHeight(this);

                //    $('.slider-enviroment .item').each(function(){
                //         var text=$(this).find('.wrap-text .text');
                //         var btn=text.siblings('.btn-seeMore');
                //         var more=text.siblings('.btn-seeMore').data('text-more');
                //         text.removeClass('isOpen').addClass('isClose');
                //         btn.removeClass('js-seeLessEnviroment');
                //         btn.addClass('js-seeMoreEnviroment');
                //         btn.html(more);

                //     });




                // $('.js-seeMoreEnviroment').on('click', utils_2021.empresaEnviroment.open);
                //$('.slider-enviroment .slick-list, .slider-enviroment').height($('.slider-enviroment .slick-sctive').outerHeight());

            });
        }
    }
}

// utils_2021.sliderPostNews={
//     //use post, blog (fama)
//     init(){
//         if($('.wrap-slider-new').length > 0){
//             $('.wrap-slider-new').slick({
//                 centerMode: false,
//                 slidesToShow: 3.5,
//                 infinite: false,
//                 dots: false,
//                 speed: 500,
//                 slidesToScroll: 1,
//                 focusOnSelect: false,
//                 arrows: true,
//                 autoplay: false,

//                 draggable: true,
//                 touchMove: true,
//                 swipeToSlide: true,
//                 swipe: true,
//                 lazyLoad: 'ondemand',

//                 //variableWidth: true,
//                 responsive: [{
//                     breakpoint: 991,
//                     settings: {
//                         swipeToSlide: true,
//                         arrows: true,
//                         slidesToShow: 2.5,

//                     }
//                 },
//                     {
//                         breakpoint: 768,
//                         settings: {
//                             swipeToSlide: true,
//                             arrows: false,
//                             slidesToShow: 1.05,
//                         }
//                     },

//                 ]
//             })

//         }

//         utils_2021.resizeProgressSliderNews.init();
//     }

// }

// DEPRECATED
utils_2021.resizeProgressSliderNews={
    init(){
        //use post, blog (fama)
        if($('.wrap-slider-new').length > 0){

            var $wrapProgress= $('.new-article-text .wrap-progress');
            var $wrapProgressNum=$wrapProgress.data('num');
            var viwePort= $('body').outerWidth();


            if($wrapProgressNum > 3 && viwePort >= 991 ){

                $wrapProgress.removeClass('notSee');
                var $slider=$('.wrap-slider-new');
                var $progressBar = $('.wrapper .progress');
                var $progressBarLabel = $( '.wrapper .slider__label' );

                $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                    var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

                    $progressBar
                        .css('background-size', calc + '% 100%')
                        .attr('aria-valuenow', calc );

                    $progressBarLabel.text( calc + '% completed' );
                });
            }else if($wrapProgressNum > 2 && viwePort >= 768){

                $wrapProgress.removeClass('notSee');
                var $slider=$('.wrap-slider-new');
                var $progressBar = $('.wrapper .progress');
                var $progressBarLabel = $( '.wrapper .slider__label' );

                $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                    var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

                    $progressBar
                        .css('background-size', calc + '% 100%')
                        .attr('aria-valuenow', calc );

                    $progressBarLabel.text( calc + '% completed' );
                });
            }
            else if($wrapProgressNum > 1 && viwePort <= 767){

                $wrapProgress.removeClass('notSee');
                var $slider=$('.wrap-slider-new');
                var $progressBar = $('.wrapper .progress');
                var $progressBarLabel = $( '.wrapper .slider__label' );

                $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                    var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

                    $progressBar
                        .css('background-size', calc + '% 100%')
                        .attr('aria-valuenow', calc );

                    $progressBarLabel.text( calc + '% completed' );
                });
            }else{
                $wrapProgress.addClass('notSee');
            }

        }
    }
}

utils_2021.sliderShopMap={
    init(){
        //use encuentra tu tienda (fama) ??
        if($('.slider-shop').length > 0){
            $('.slider-shop').slick({
                centerMode: false,
                slidesToShow: 1,
                infinite: false,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                focusOnSelect: true,
                arrows: true,
                autoplay: false,

                draggable: false,
                touchMove: false,
                swipeToSlide: false,
                swipe: false,

                variableWidth: true,

            })

            var $slider=$('.slider-shop');
            var $progressBar = $('.map-container-info .progress');
            var $progressBarLabel = $( '.map-container-info .slider__label' );

            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

                $progressBar
                    .css('background-size', calc + '% 100%')
                    .attr('aria-valuenow', calc );

                $progressBarLabel.text( calc + '% completed' );
            });
        }
    },
    seeFullImage(){
        var imgFullBackground;
        var $slider=$(this).closest('.slider-shop');
        var srcCurrentImg=$(this).find('img').attr('src');

        imgFullBackground=$(this).closest('.container-shops').find('.wrap-full-background');
        imgFullBackground.find('.imgActive').attr('src', srcCurrentImg);
        imgFullBackground.addClass('isOpen');

    },

    closeFullImage(){
        var imgFullBackground=$(this).closest('.wrap-full-background');
        imgFullBackground.removeClass('isOpen');
    },


    binds(){
        $('.js-openFull').on('click', this.seeFullImage);
        $('.js-closeFull').on('click', this.closeFullImage);
    }
}

// utils_2021.calcHotpoints={
//     //use HOME (fama)
//     init(){
//         //e.preventDefault();
//         //let slider=$('.slider-home');
//         //let container=slider.find('.item-slide .aux-item');
//         let sliderImgs = $('.slider-home .item-slide .aux-item .slider-image');
//         sliderImgs.each(function(){
//             let image = $(this)[0];
//             let percentageRendered = image.height / image.naturalHeight;
//             let totalWidth = percentageRendered * image.naturalWidth;
//             let hiddenWidth = totalWidth - image.width;
//             let hiddenPerSide = hiddenWidth / 2;

//             $(this).siblings().each(function(){
//                 let correctWidthPositionPixels = (($(this).data('width')/100) * totalWidth) - hiddenPerSide;
//                 let correctWidthPositionPercent = (correctWidthPositionPixels / image.width)*100;
//                 $(this).css("left", correctWidthPositionPercent + "%");
//             });
//         })
//     }
// }

//use (fama)
utils_2021.element={
    init() {
        $(window).trigger('scroll');
    },
    checkScroll(e) {

        if($('.js-animatable').length>0){
            let animatableElements = $('.js-animatable:not(.isVisible)');
            let windowHeight = window.outerHeight;
            let scrollTop = $('html').scrollTop();

            // Show normal animatable elements
            for (let i = 0; i < animatableElements.length; i++) {
                let positionFromTop = animatableElements[i].getBoundingClientRect().top;
                let delay = 600;
                let aux = 100;

                if (positionFromTop - windowHeight <= 0) {
                    setTimeout(function () {
                        $(animatableElements[i]).addClass('isVisible');
                        $(animatableElements[i]).closest('.wrap-animate').find('.animate').addClass('isVisible');
                    }, i * delay);
                }
            }
        }
        if($('.js-animatable.isVisible').length > 0){
            let animatableElementsOut = $('.js-animatable.isVisible');
            let windowHeight = window.outerHeight;
            let scrollTop = $('html').scrollTop();
            for (let i = 0; i < animatableElementsOut.length; i++) {

                let positionFromTop = animatableElementsOut[i].getBoundingClientRect().top;
                let aux = 100;
                let delayOut = 300;

                if (positionFromTop - windowHeight > 0) {
                    //setTimeout(function () {
                    $(animatableElementsOut[i]).removeClass('isVisible');
                    $(animatableElementsOut[i]).closest('.wrap-animate').find('.animate').removeClass('isVisible');
                    //}, i * delayOut);
                }
            }
        }

        // srollMuve elements

        if($('.js-scrollMuve').length > 0){
            let fotoMuve= $('.foto-muve');
            let fotoMuve2=$('.foto-muve2');
            let fotoMuve3=$('.foto-muve3');
            let bloqueMuve4=$('.bloque-muve');
            let fotoMuve4= $('.foto-muve4');
            var inMobile=false;
            var viwePort= parseFloat($('body').css('width'));
            if(viwePort<= 975){
                inMobile=true;
            }

            let windowHeight = window.innerHeight;
            let scrollTop = $(window).scrollTop();
            if(!inMobile){
                if (fotoMuve.length > 0) {
                    let posFotoMuve = $(fotoMuve).offset().top;
                    let heightFotoMuve = $(fotoMuve).height();
                    let translateYFotoMuve;
                    if ((windowHeight + scrollTop >= posFotoMuve) && (scrollTop <= posFotoMuve + heightFotoMuve)) {
                        translateYFotoMuve = 200 - (posFotoMuve * 200 / (windowHeight + scrollTop));
                    } else if (windowHeight + scrollTop <= posFotoMuve) {
                        translateYFotoMuve = 0;
                    } else {
                        translateYFotoMuve = 25;
                    }
                    $(fotoMuve).css({
                        'opacity': 1,
                        'transform': 'translateY('+translateYFotoMuve+'rem)'
                    });
                }
            }
            if(!inMobile){
                if (fotoMuve2.length > 0) {
                    let posFotoMuve = $(fotoMuve2).offset().top;
                    let heightFotoMuve = $(fotoMuve2).height();
                    let translateYFotoMuve;
                    if ((windowHeight + scrollTop >= posFotoMuve) && (scrollTop <= posFotoMuve + heightFotoMuve)) {
                        translateYFotoMuve = 50 - (posFotoMuve * 50 / (windowHeight + scrollTop));
                    } else if (windowHeight + scrollTop <= posFotoMuve) {
                        translateYFotoMuve = 0;
                    } else {
                        translateYFotoMuve = 30;
                    }
                    $(fotoMuve2).css({
                        'opacity': 1,
                        'transform': 'translateY('+translateYFotoMuve+'rem)'
                    });
                }
            }
            if(!inMobile){
                if (fotoMuve3.length > 0) {
                    let posFotoMuve = $(fotoMuve3).offset().top;
                    let heightFotoMuve = $(fotoMuve3).height();
                    let translateYFotoMuve;
                    if ((windowHeight + scrollTop >= posFotoMuve) && (scrollTop <= posFotoMuve + heightFotoMuve)) {
                        translateYFotoMuve = 20 - (posFotoMuve * 20 / (windowHeight + scrollTop));
                    } else if (windowHeight + scrollTop <= posFotoMuve) {
                        translateYFotoMuve = 0;
                    } else {
                        translateYFotoMuve = 10;
                    }
                    $(fotoMuve3).css({
                        'opacity': 1,
                        'transform': 'translateY('+translateYFotoMuve+'rem)'
                    });
                }
            }
            if(!inMobile){
                if (fotoMuve4.length > 0) {
                    let posFotoMuve = $(fotoMuve4).offset().top;
                    let heightFotoMuve = $(fotoMuve4).height();
                    let translateYFotoMuve;
                    if ((windowHeight + scrollTop >= posFotoMuve) && (scrollTop <= posFotoMuve + heightFotoMuve)) {
                        translateYFotoMuve = 300 - (posFotoMuve * 300 / (windowHeight + scrollTop));
                    } else if (windowHeight + scrollTop <= posFotoMuve) {
                        translateYFotoMuve = 3;
                    } else {
                        translateYFotoMuve = 25;
                    }
                    $(fotoMuve4).css({
                        'opacity': 1,
                        'transform': 'translateY('+translateYFotoMuve+'rem)'
                    });
                }
            }
            if(!inMobile){
                if (bloqueMuve4.length > 0) {
                    let posFotoMuve = $(bloqueMuve4).offset().top;
                    let heightFotoMuve = $(bloqueMuve4).height();
                    let translateYFotoMuve;
                    if ((windowHeight + scrollTop >= posFotoMuve) && (scrollTop <= posFotoMuve + heightFotoMuve)) {
                        translateYFotoMuve = 130 - (posFotoMuve * 200 / (windowHeight + scrollTop));
                    } else if (windowHeight + scrollTop <= posFotoMuve) {
                        translateYFotoMuve = -30;
                    } else {
                        translateYFotoMuve = -200;
                    }
                    $(bloqueMuve4).css({
                        'opacity': 1,
                        'transform': 'translateY('+translateYFotoMuve+'%)'
                    });
                }
            }

        }


        // FADE and HADE titles


        if($('.js-fade-animation').length > 0){
            let animatableElements = $('.js-fade-animation:not(.isVisible)');
            let windowHeight = window.outerHeight;
            let scrollTop = $('html').scrollTop();

            // Show normal animatable elements
            for (let i = 0; i < animatableElements.length; i++) {

                let positionFromTop = animatableElements[i].getBoundingClientRect().top;
                let delay = 50;
                let aux = 10;

                if (positionFromTop - windowHeight <= 0) {
                    setTimeout(function () {
                        $(animatableElements[i]).addClass('isVisible');
                    }, delay * aux);
                }
            }
        }



        if($('.js-fade-animation.isVisible').length > 0){
            let animatableElementsOut = $('.js-fade-animation.isVisible');
            let windowHeight = window.outerHeight;

            for (let i = 0; i < animatableElementsOut.length; i++) {
                let positionFromTop = animatableElementsOut[i].getBoundingClientRect().top;
                if (positionFromTop - windowHeight > 0) {
                    $(animatableElementsOut[i]).removeClass('isVisible');
                }
            }
        }


        // FADE of image


        if($('.js-fade-img').length > 0){
            let animatableElements = $('.js-fade-img:not(.isVisible)');
            let windowHeight = window.outerHeight;
            let scrollTop = $('html').scrollTop();

            // Show normal animatable elements
            for (let i = 0; i < animatableElements.length; i++) {

                let positionFromTop = animatableElements[i].getBoundingClientRect().top;
                let delay = 90;
                let aux = 10;

                if (positionFromTop - windowHeight <= 0) {
                    setTimeout(function () {
                        $(animatableElements[i]).addClass('isVisible');
                    }, delay * aux);
                    setTimeout(function () {
                        $(animatableElements[i]).addClass('bgTransparent');
                    }, (delay * aux) + 2000);

                }
            }
        }



        if($('.js-fade-up-down').length > 0){
            let animatableElements = $('.js-fade-up-down:not(.isVisible)');
            let windowHeight = window.outerHeight;
            let scrollTop = $('html').scrollTop();

            // Show normal animatable elements
            for (let i = 0; i < animatableElements.length; i++) {

                let positionFromTop = animatableElements[i].getBoundingClientRect().top;
                let delay = 90;
                let aux = 10;

                if (positionFromTop - windowHeight <= 0) {
                    setTimeout(function () {
                        $(animatableElements[i]).addClass('isVisible');
                    }, delay * aux);
                    setTimeout(function () {
                        $(animatableElements[i]).addClass('bgTransparent');
                    }, (delay * aux) + 2000);

                }
            }
        }


        if($('.module-list-lateral-inner .sidebar-categories').length > 0 && $('.section-history-timeline').length > 0){
            var blockTimline=$('.section-history-timeline');
            $('.module-list-lateral-inner .sidebar-categories .item').each(function(){
                var element=$(this);
                var elementPositionTop = element.offset().top;
                var blockTimlineTop = blockTimline.offset().top;
                if(elementPositionTop>=blockTimlineTop){
                    element.addClass('isEntry');
                }else{
                    element.removeClass('isEntry');
                }

            });

        }

        if($('.module-list-lateral-inner .sidebar-categories').length > 0 && $('.ourTours-medioambiente').length > 0){
            var blockTimline=$('.ourTours-medioambiente');
            $('.module-list-lateral-inner .sidebar-categories .item').each(function(){
                var element=$(this);
                var elementPositionTop = element.offset().top;
                var blockTimlineTop = blockTimline.offset().top;
                if(elementPositionTop>=blockTimlineTop){
                    element.addClass('isEntry');
                }else{
                    element.removeClass('isEntry');
                }

            });


        }

    },

    binds() {
        $(window).on('scroll', this.checkScroll);
    }
}
utils_2021.empresa={
    open(){
        let text=$(this).closest('.wrap-text').find('.text');
        let less=$(this).data('text-less');
        text.removeClass('isClose').addClass('isOpen');

        $(this).removeClass('js-seeMore');
        $(this).addClass('js-seeLess');
        $(this).html(less);
        $('.js-seeLess').on('click', utils_2021.empresa.close);
    },
    close(){
        let text=$(this).closest('.wrap-text').find('.text');
        let more=$(this).data('text-more');
        text.removeClass('isOpen').addClass('isClose');

        $(this).removeClass('js-seeLess');
        $(this).addClass('js-seeMore');
        $(this).html(more);
        $('.js-seeMore').on('click', utils_2021.empresa.open);
    },
    binds(){
        $('.js-seeMore').on('click', this.open);
        $('.js-seeLess').on('click', this.close);
    }
}
// utils_2021.empresaEnviroment={
//     open(){

//         let $slider = $('.slider-enviroment');
//         let text=$(this).closest('.wrap-text').find('.text');
//         let less=$(this).data('text-less');
//         text.removeClass('isClose').addClass('isOpen');

//         $(this).removeClass('js-seeMoreEnviroment');
//         $(this).addClass('js-seeLessEnviroment');
//         $(this).html(less);

//         if($(this).hasClass('js-seeLessEnviroment')){
//             $(this).on('click', utils_2021.empresaEnviroment.close);
//         }

//         $slider.find('.slick-list').height('auto');
//     },
//     close(){
//         let $slider = $('.slider-enviroment');
//         let text=$(this).closest('.wrap-text').find('.text');
//         let more=$(this).data('text-more');
//         text.removeClass('isOpen').addClass('isClose');

//         $(this).removeClass('js-seeLessEnviroment');
//         $(this).addClass('js-seeMoreEnviroment');
//         $(this).html(more);
//         if($(this).hasClass('js-seeMoreEnviroment')){
//             $(this).on('click', utils_2021.empresaEnviroment.open);
//         }

//         $slider.find('.slick-list').height('auto');
//     },

//     setHidden(slider){
//         $('.slick-track .slick-slide:not(.slick-active)', slider).each(function(item) {
//             // add current active slide height to our active slides array
//             $(this).addClass('notSee');
//             $(this).removeClass('see');

//         });
//     },
//     multiSlideAdaptiveHeight(slider) {
//         // set our vars
//         let activeSlides = [];
//         let tallestSlide = 0;

//         // very short delay in order for us get the correct active slides
//         setTimeout(function() {

//             // loop through each active slide for our current slider
//             $('.slick-track .slick-active', slider).each(function(item) {

//                 // add current active slide height to our active slides array
//                 activeSlides[item] = $(this).outerHeight();

//             });


//             // for each of the active slides heights
//             activeSlides.forEach(function(item) {

//                 // if current active slide height is greater than tallest slide height
//                 if (item > tallestSlide) {

//                     // override tallest slide height to current active slide height
//                     tallestSlide = item;

//                 }

//             });

//             // set the current slider slick list height to current active tallest slide height
//             $('.slider-ourTours.slick-vertical .slick-list').height(tallestSlide);
//             // $('.slider-ourTours.slick-vertical .slick-list')[0].style.setProperty('height', tallestSlide, 'important');
//             $(".slider-ourTours.slick-vertical .slick-list").css("cssText", "height:"+tallestSlide+"px !important");
//         }, 10);

//     },

//     seeMoreSustainability(){
//         let btn=$(this);
//         let numPorPage=10;
//         let currentItem=btn.closest('.wrap-content').find('.wrap-item.visible:last');
//         var i;

//         let numCurrentItem=currentItem.data('current');
//         let total=currentItem.data('total');
//         if((numCurrentItem+numPorPage)<=total){
//             for( i=numCurrentItem; i<=numCurrentItem+numPorPage; i++){
//                 btn.closest('.wrap-content').find('.wrap-item.hidden[data-current="'+i+'"]').removeClass('hidden').addClass('visible');
//                 if(i===total){
//                     btn.hide();
//                 }
//             }
//         }else{
//             for( i=numCurrentItem; i<=total; i++){
//                 btn.closest('.wrap-content').find('.wrap-item.hidden[data-current="'+i+'"]').removeClass('hidden').addClass('visible');
//                 if(i===total){
//                     btn.hide();
//                 }
//             }
//         }
//     },
//     binds(){
//         $('.js-seeMoreEnviroment').on('click', this.open);
//         $('.js-seeLessEnviroment').on('click', this.close);
//         $('.js-seeMoreSustainability').on('click', this.seeMoreSustainability);
//     }
// }

// hide/shoe nav with scroll
// utils_2021.menuHideShow={
    /*
    init(){
        var prevScrollpos = window.pageYOffset;

        window.onscroll = function() {
            var inMobile = parseFloat($('body').css('width')) <= 991;


            var currentScrollPos = window.pageYOffset;

            if($('#topbar').length > 0 && !$('#topbar').hasClass('is-open')){

                if (prevScrollpos >= currentScrollPos || window.pageYOffset < 102) {
                    document.getElementById("topbar").style.top = "0";

                }else{
                    document.getElementById("topbar").style.top = "-10.2rem";
                    if(inMobile){
                        document.getElementById("topbar").style.top = "-7rem";

                    }

                }
                prevScrollpos = currentScrollPos;
            }

        }
    },
    */

//     toggleDropDown(){
//         $(this).toggleClass('isOpen');
//     },

//     binds(){
//         $('.topbar .js-dropDown').on('click', this.toggleDropDown);
//     }

// }

// utils_2021.fadeText={
//     init(){
//         if($('body').outerWidth() > 991){
//             function callback(entries, observer) {
//                 entries.forEach(entry => {
//                     if(entry.isIntersecting){
//                         entry.target.classList.add('cssanimation', 'fadeInBottom');
//                     }
//                 });
//             }

//             let options = {
//                 root: null,
//                 rootMargin: '0px',
//                 threshold: 1.0
//             }

//             let observer = new IntersectionObserver(callback, options);
//             let target = $('.js-fadeIn');
//             if(target.length > 0){
//                 [...target].forEach(item => {
//                     observer.observe(item);
//                 });
//             }
//         }
//     }

// }

// utils_2021.fadeMvl={
//     init() {
//         this.binds();
//         $(window).trigger('scroll');
//     },

//     checkScroll(){
//         if($('.js-fadeMvl').length > 0 ){
//             var windowTop = $(document).scrollTop();

//             $('.js-fadeMvl').each(function(){
//                 var element=$(this);
//                 var elementPositionTop = element.offset().top;
//                 var windowBottom = windowTop + window.innerHeight;

//                 if (elementPositionTop <= (windowBottom - 350) ) {
//                     element.removeClass('opacity-0-mvl');
//                 }


//             });

//         }
//     },

//     binds() {
//         if($('.js-fadeMvl').length > 0){
//             $(window).on('scroll', this.checkScroll);
//         }

//     }
// };

// utils_2021.fadeTextMvl={
//     //NO SE USA??
//     /*
//     init(){

//         if($('.section-history-timeline-mvl').length >  0){

//             function callback(entries, observer) {


//                 entries.forEach(entry => {
//                     if(entry.isIntersecting){
//                         entry.target.classList.add('cssanimationmvl', 'fadeInBottomMvl');
//                     }
//                 });
//             }

//             let options = {
//                 root: null,
//                 rootMargin: '0px',
//                 threshold: 1.0
//             }

//             let observer = new IntersectionObserver(callback, options);
//             let target = $('.js-fadeInMvl');
//             if(target.length > 0){
//                 [...target].forEach(item => {
//                     observer.observe(item);
//                 });
//             }
//         }
//     }
//     */
// }

// // fade out image when slider image intersects viewport
// utils_2021.fadeTextTranslate={
//     //use HOME (fama)
//     init(){
//         if($('.isHome').length > 0){
//             function callback(entries, observer) {
//                 entries.forEach(entry => {
//                     if(entry.isIntersecting){
//                         entry.target.classList.add('cssanimationTranslate', 'fadeInBottomTranslate');
//                     }
//                 });
//             }

//             let options = {
//                 root: null,
//                 rootMargin: '0px',
//                 threshold: 1.0
//             }

//             let observer = new IntersectionObserver(callback, options);
//             let target = $('.js-fadeInTranslate');
//             if(target.length > 0){
//                 [...target].forEach(item => {
//                     observer.observe(item);
//                 });
//             }

//         }else{
//             if($('body').outerWidth() > 991){
//                 function callback(entries, observer) {
//                     entries.forEach(entry => {
//                         if(entry.isIntersecting){
//                             entry.target.classList.add('cssanimationTranslate', 'fadeInBottomTranslate');
//                         }
//                     });
//                 }

//                 let options = {
//                     root: null,
//                     rootMargin: '0px',
//                     threshold: 1.0
//                 }

//                 let observer = new IntersectionObserver(callback, options);
//                 let target = $('.js-fadeInTranslate');
//                 if(target.length > 0){
//                     [...target].forEach(item => {
//                         observer.observe(item);
//                     });
//                 }
//             }
//         }
//     }

// }

// // Fade in when intersects with viewport (Sliders)
// utils_2021.fadeInRight={
//     init(){
//         //use HOME (fama)
//         //if($('body').outerWidth() > 991){

//         function callback(entries, observer) {
//             entries.forEach(entry => {
//                 if(entry.isIntersecting){
//                     entry.target.classList.add('js-right');
//                 }
//             });
//         }

//         let options = {
//             root: null,
//             rootMargin: '0px',
//             threshold: 0.5
//         }

//         let observer = new IntersectionObserver(callback, options);
//         let target = $('.js-fadeInRight');
//         if(target.length > 0){
//             [...target].forEach(item => {
//                 observer.observe(item);
//             });
//         }
//         //}
//     }

// }

// utils_2021.fadeUpYear={
//     init(){
//         //use HOME (fama)
//         if($('body').outerWidth() > 991){
//             function callback(entries, observer) {
//                 entries.forEach(entry => {
//                     if(entry.isIntersecting){
//                         entry.target.classList.add('cssanimationUp', 'fadeUp');
//                     }
//                 });
//             }

//             let options = {
//                 root: null,
//                 rootMargin: '0px',
//                 threshold: 1.0
//             }

//             let observer = new IntersectionObserver(callback, options);
//             let target = $('.js-fadeUp');
//             if(target.length > 0){
//                 [...target].forEach(item => {
//                     observer.observe(item);
//                 });
//             }
//         }

//     }

// }

// utils_2021.fadeTextTranslateLeft={
//     init(){
//         //use HOME (fama)
//         if( $('body').outerWidth() > 991){
//             function callback(entries, observer) {
//                 entries.forEach(entry => {
//                     if(entry.isIntersecting){
//                         entry.target.classList.add('cssanimationTranslateLeft', 'fadeInLeft');
//                     }
//                 });
//             }

//             let options = {
//                 root: null,
//                 rootMargin: '0px',
//                 threshold: 1.0
//             }

//             let observer = new IntersectionObserver(callback, options);
//             let target = $('.js-fadeInLeft');
//             if(target.length > 0){
//                 [...target].forEach(item => {
//                     observer.observe(item);
//                 });
//             }
//         }

//     }

// }

// selected items active on scroll
// utils_2021.medioambienteScroll={
//     scrollable: true,
//     init(){
//         if($('.sofas-medioambiente').length > 0){
//             utils_2021.medioambienteScroll.bindScroll()
//         }

//     },

//     scrollSection(e){
//         let elem=$(e.currentTarget);
//         let sectionID=elem.attr('data-index');
//         let elemScroll=$(`.section-empresa[data-index="${sectionID}"]`);
//         utils_2021.medioambienteScroll.scrollable = false
//         $('html, body').animate({
//             scrollTop: elemScroll.offset().top - 102
//         }, 600, 'swing',
//         () => {
//             console.log('animation finish')
//             utils_2021.medioambienteScroll.scrollable = true
//         });
//     },

//     enableEntry(index) {
//         if (!$(`.inner-mediaombiente[data-index='${index}']`).hasClass('isSelected')) {
//             $('.inner-mediaombiente').removeClass('isSelected'); // eliminamos la clase active del que la tenga
//             $(`.inner-mediaombiente[data-index="${index}"]`).addClass('isSelected');
//         }
//     },

//     bindScroll() {
//         $(window).on('scroll', () => {
//             if(utils_2021.medioambienteScroll.scrollable) {
//                 $('.module-list-container >.sofas-medioambiente').each((i, item) => {
//                     let $item = $(item)
//                     let index=$item.data('index');
//                     if ($(window).scrollTop() >= $item.position().top - 300) {
//                         utils_2021.medioambienteScroll.enableEntry(index)
//                     }
//                 });
//             }
//         });
//     },

//     binds(){
//         $('.js-scrollSection').on('click', e => { this.scrollSection(e) });
//     }
// }

// utils_2021.history={
//     init(){
//         if($('.timeline.nav').length > 0){
//             let stickyTop = 0,
//                 scrollTarget = false

//             let timeline = $('.timeline.nav'),
//                 items = $('li', timeline),
//                 milestones = $('.timeline-section .milestone'),
//                 lines=$('.line-inner'),
//                 offsetTop = parseInt(timeline.css('top'))

//             const TIMELINE_VALUES = {
//                 start: 190,
//                 step: 30
//             }

//             $(window).resize(function () {
//                 timeline.removeClass('fixed')

//                 stickyTop = timeline.offset().top - offsetTop

//                 $(window).trigger('scroll');


//             }).trigger('resize')

//             $(window).scroll(function () {
//                 if ($(window).scrollTop() > stickyTop) {
//                     timeline.addClass('fixed')
//                 } else {
//                     timeline.removeClass('fixed')
//                 }
//             }).trigger('scroll')

//             items.find('span').click(function () {

//                 $('.section-time').each(function(){
//                     $(this).removeClass('isActive');
//                 });

//                 let li = $(this).parent(),
//                     index = li.index(),
//                     milestone = milestones.eq(index)
//                 milestone.closest('.section-time').addClass('isActive');

//                 $('.js-scrollElem').each(function(){
//                     if($(this).data('index') <= index){
//                         $(this).addClass('isActive')
//                     }
//                 })

//                 if (! li.hasClass('active') && milestone.length) {
//                     scrollTarget = index

//                     let scrollTargetTop = milestone.offset().top - 80

//                     $('html, body').animate({ scrollTop: scrollTargetTop }, {

//                         duration: 400,
//                         complete: function complete() {
//                             scrollTarget = false;
//                             utils_2021.history.setActiveButtom();
//                         }
//                     })

//                 }
//             })

//             $(window).scroll(function () {
//                 let lastElem=$('.timeline.nav li:last-child');
//                 let max=lastElem.data('index');
//                 let viewLine = $(window).scrollTop() + $(window).height() / (max + 1),
//                     active = -1

//                 if (scrollTarget === false) {
//                     milestones.each(function () {
//                         if ($(this).offset().top - viewLine > 0) {
//                             return false
//                         }

//                         active++;
//                         let index2= $('.timeline.nav li.active').data('index');
//                         let lineInner = $('.line-inner');
//                         lineInner.each(function(){
//                             if($(this)[0].dataset.index <= index2){
//                                 $(this).addClass('isActive');
//                             }
//                         });
//                         let scrollThings = $('.js-scrollElem');
//                         scrollThings.each(function(){
//                             if($(this)[0].dataset.index <= index2){
//                                 $(this).addClass('isActive');
//                             }
//                         });

//                         var currElem=$('.js-scrollElem');
//                         currElem.each(function(index, elem){
//                             if($(elem).hasClass('isActive')){
//                                 let elemPosTop = $(elem).offset().top;
//                                 let elemHeight = elem.getBoundingClientRect().height;

//                                 let scrollTop = $('html').scrollTop();

//                                 let procentaje = 100 * ((scrollTop - elemPosTop) / elemHeight);
//                                 if (procentaje > 0) {
//                                     $('.line-inner[data-index="' + index + '"]').find('.line-content').css('height', procentaje + '%')
//                                 }else{
//                                     $('.line-inner[data-index="' + index + '"]').find('.line-content').css('height', '0%');
//                                 }
//                             }else{
//                                 $('.line-inner[data-index="' + index + '"]').find('.line-content').css('height', '0%');
//                             }
//                         })
//                     })
//                 } else {
//                     $('.line-inner').removeClass('isActive');
//                     $('.js-scrollElem').removeClass('isActive');
//                     active = scrollTarget;
//                     let index2= $('.timeline.nav li.active').data('index');
//                     $('.line-inner[data-index="'+index2+'"]').addClass('isActive');
//                     $('.js-scrollElem[data-index="'+index2+'"]').addClass('isActive');
//                 }

//                 timeline.css('top', -1 * active * TIMELINE_VALUES.step + TIMELINE_VALUES.start + 'px')

//                 items.filter('.active').removeClass('active')
//                 if(active > max) {
//                     active = max;
//                 }
//                 items.eq(active != -1 ? active : 0).addClass('active');

//                 utils_2021.history.setActiveButtom();

//             }).trigger('scroll')



//         }
//     },
//     changeDecada(){

//         let elem=$(this);
//         let currElem=$('.timeline.nav li.active');
//         let indexCurElem=currElem.data('index');
//         let lastElem=$('.timeline.nav li:last-child');
//         let firstElem=$('.timeline.nav li:first-child');
//         let max=lastElem.data('index');
//         let min=firstElem.data('index');

//         let stickyTop = 0,
//             scrollTarget = false;

//         let timeline = $('.timeline.nav'),
//             items = $('li', timeline),
//             milestones = $('.timeline-section .milestone'),
//             lines=$('.line-inner'),
//             offsetTop = parseInt(timeline.css('top'))

//         const TIMELINE_VALUES = {
//             start: 190,
//             step: 30
//         }

//         if(elem.hasClass('js-prev-decada')){

//             if(indexCurElem>min){
//                 elem.addClass('isActive');
//                 let indexNew=indexCurElem-1;

//                 if((indexNew-1)<0){
//                     elem.removeClass('isActive');
//                 }

//                 $('.section-time').each(function(){
//                     $(this).removeClass('isActive');
//                 });

//                 if(milestones.eq(indexNew)){
//                     var milestone = milestones.eq(indexNew)
//                     milestone.closest('.section-time').addClass('isActive');
//                     if(indexNew<max){
//                         $('.js-next-decada').addClass('isActive');
//                     }else{
//                         $('.js-next-decada').removeClass('isActive');
//                     }
//                 }


//                 if ( milestone.length) {
//                     scrollTarget = indexNew

//                     let scrollTargetTop = milestone.offset().top - 80

//                     $('html, body').animate({ scrollTop: scrollTargetTop }, {
//                         duration: 400,
//                         complete: function complete() {
//                             scrollTarget = false
//                         }
//                     })
//                 }
//             }else{
//                 elem.removeClass('isActive');
//                 if(max==0){
//                     $('.js-next-decada').removeClass('isActive');
//                     $('.js-prev-decada').removeClass('isActive');
//                 }
//             }

//         }else{

//             if(indexCurElem<max){
//                 let indexNew=indexCurElem+1;
//                 $('.section-time').each(function(){
//                     $(this).removeClass('isActive');
//                 });

//                 if((indexNew+1)>max){
//                     elem.removeClass('isActive');
//                 }

//                 $('.section-time').each(function(){
//                     $(this).removeClass('isActive');
//                 });

//                 if(milestones.eq(indexNew)){
//                     var milestone = milestones.eq(indexNew)
//                     milestone.closest('.section-time').addClass('isActive');
//                     if(indexNew>min){
//                         $('.js-prev-decada').addClass('isActive');
//                     }else{
//                         $('.js-prev-decada').removeClass('isActive');
//                     }
//                 }

//                 if ( milestone.length) {
//                     scrollTarget = indexNew

//                     let scrollTargetTop = milestone.offset().top - 80

//                     $('html, body').animate({ scrollTop: scrollTargetTop }, {
//                         duration: 400,
//                         complete: function complete() {
//                             scrollTarget = false
//                         }
//                     })

//                 }

//             }else{
//                 elem.removeClass('isActive');
//                 if(max===indexCurElem && min!=max){
//                     elem.removeClass('isActive');
//                     $('.js-prev-decada').addClass('isActive');
//                 }else if(max===indexCurElem && min===max){
//                     elem.removeClass('isActive');
//                     $('.js-prev-decada').removeClass('isActive');
//                 }
//             }
//         }
//     },

//     setActiveButtom(){
//         let currElem1=$('.timeline.nav li.active');
//         let indexCurElem=currElem1.data('index');
//         let lastElem=$('.timeline.nav li:last-child');
//         let firstElem=$('.timeline.nav li:first-child');
//         let max=lastElem.data('index');
//         let min=firstElem.data('index');
//         if(min!=max && max>0){
//             if(min!=indexCurElem && max!=indexCurElem){
//                 $('.js-prev-decada').addClass('isActive');
//                 $('.js-next-decada').addClass('isActive');
//             }else if(min===indexCurElem && max!=indexCurElem){
//                 $('.js-prev-decada').removeClass('isActive');
//                 $('.js-next-decada').addClass('isActive');
//             }else if(min!==indexCurElem && max===indexCurElem){
//                 $('.js-prev-decada').addClass('isActive');
//                 $('.js-next-decada').removeClass('isActive');
//             }

//         }else{
//             $('.js-prev-decada').removeClass('isActive');
//             $('.js-next-decada').removeClass('isActive');
//         }
//     },
//     binds(){
//         $('.js-prev-decada').on('click', this.changeDecada);
//         $('.js-next-decada').on('click', this.changeDecada);
//     }

// }

utils_2021.curriculum={
    seeForm(){
        $(this).closest('.content').find('.form-work').removeClass('hidden');
        $(this).closest('.content').find('.curriculum-faqs').removeClass('hidden');
        $(this).fadeOut();
    },
    openFaqCV(){
        $(this).closest('.result').toggleClass('isOpen');
    },

    binds(){
        $('.js-seeFormWorkWithUs').on('click', this.seeForm);
        $('.js-openFaqCV').on('click', this.openFaqCV);
    }
},

    utils_2021.concurso={
        seeFormConcurso(){
            $(this).closest('.wrap-form-competition').find('.wrap-content-form').removeClass('hidden');
            $(this).fadeOut();
        },

        binds(){
            $('.js-seeFormConcurso').on('click', this.seeFormConcurso);
        }
    },

    utils_2021.contact={
        toggleCollapse(){
            let elem=$(this);
            //elem.toggleClass('isOpen');
            if(elem.siblings('.nav-members').length > 0){
                elem.siblings('.nav-members').toggleClass('collapse');
            }
        },

        binds(){
            $('.js-toggleCollapse').on('click', this.toggleCollapse);
        }
    }

utils_2021.utils={
    seeCart(){
        $(this).find('.wrap-info').addClass('isVisible');
        $(this).find('.aux-text').addClass('isVisible');
        $(this).find('.aux-text').show('slow');
    },
    notSeeCart(){
        $(this).find('.wrap-info.isVisible').removeClass('isVisible');
        $(this).find('.aux-text.isVisible').removeClass('isVisible');
        $(this).find('.aux-text').hide('slow');

    },

    /*
    binds(){
        //$('.js-seeCart').on('mouseenter', this.seeCart);
        //$('.js-seeCart').on('mouseleave', this.notSeeCart);

    }
    */
}

utils_2021.init = () => {
    //utils_2021.menuHideShow.init(); //prepear for delete
    // utils_2021.sliderHome.init();
    // utils_2021.fadeText.init();
    //utils_2021.fadeTextMvl.init(); //prepear for delete
    // utils_2021.fadeMvl.init();
    // utils_2021.fadeTextTranslate.init();
    // utils_2021.fadeTextTranslateLeft.init();
    // utils_2021.fadeUpYear.init();
    // utils_2021.fadeInRight.init();
    // if($('.slider-home').length>0){
    //     // utils_2021.calcHotpoints.init();
    // }
    // utils_2021.nav_horizontal.init();
    // utils_2021.history.init();
    utils_2021.sliderShopMap.init();
    // utils_2021.sliderPostNews.init();
    // utils_2021.resizeProgressSliderNews.init();
    // utils_2021.medioambienteScroll.init();
    setTimeout(function () {
        utils_2021.dragLogos.init();
    }, 500);

};

utils_2021.binds = () => {
    utils_2021.element.binds();
    utils_2021.layout.binds();
    utils_2021.simulators_layout.binds();
    //utils_2021.utils.binds(); //prepaer for delete
    utils_2021.empresa.binds();
    // utils_2021.empresaEnviroment.binds();
    // utils_2021.history.binds();
    // utils_2021.menuHideShow.binds();
    utils_2021.sliderShopMap.binds();
    utils_2021.curriculum.binds();
    utils_2021.concurso.binds();
    // utils_2021.medioambienteScroll.binds();
    utils_2021.contact.binds();

};
$(window).resize(function() {
    // utils_2021.calcHotpoints.init();
    utils_2021.common.inMobile();

    let timerId=setInterval(function () {
        utils_2021.resizeProgressSliderNews.init();
    },3000);
    setTimeout(function () {
        clearTimeout(timerId);
    }, 3000)

    let timerIdHitos=setInterval(function () {
        if($('.slider-ourTours').length > 0){
            var $slider = $('.slider-ourTours');
            $slider.slick('resize');

            var maxHeight = -1;
            $('.slider-ourTours .slick-slide').each(function() {
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
            });
            $('.slider-ourTours .slick-slide').each(function() {

                if ($(this).height() < maxHeight) {
                    $(this).css('margin', Math.ceil((maxHeight-$(this).height())/2 + 5) + 'px 0');
                }
            });
        }
    },3000);
    setTimeout(function () {
        clearTimeout(timerIdHitos);
    }, 3000)

});

$(document).ready(function(e) {
    utils_2021.binds();
    utils_2021.init();
});



//END fama_new_2021
