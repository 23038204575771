export class HistoryPage {

    TIMELINE_VALUES = {
        start: 190,
        step: 30
    }

    stickyTop = 0
    scrollTarget = false
    milestones = $('.timeline-section .milestone')
    timeline = $('.timeline.nav')
    liMenuItems = $('li', this.timeline)
    offsetTop = parseInt(this.timeline.css('top'))

    constructor() {
        fama.utils.debug('HistoryPage loaded')
        this.eventListeners()
        this.init()
    }

    /**
     *
     */
    eventListeners(){
        $('.js-prev-decada').on('click', e => {this.changeDecada(e)});
        $('.js-next-decada').on('click', e => {this.changeDecada(e)});
        $(window).on('scroll', () => {this._onWindowScroll()})
        $(window).on('resize', () => {this._onWindowResize()})
        this.liMenuItems.find('span').on('click', (e) => {this._onSectionTimeClick(e)})
    }

    /**
     *
     */
    init(){
        // let timeline = $('.timeline.nav')
        // let    this.liMenuItems = $('li', this.timeline)
        // let    offsetTop = parseInt(this.timeline.css('top'))

        $(window).trigger('resize')
        $(window).trigger('scroll')


        //FIXME: Esto no funciona muy bien. Habría que repensarlo.

        $(window).on('scroll', () => {
            let lastElem = $('.timeline.nav li:last-child');
            let max = lastElem.data('index');
            let viewLine = $(window).scrollTop() + $(window).height() / (max + 1)
            let active = -1

            if (this.scrollTarget === false) {
                this.milestones.each((i, e) => {
                    let $element = $(e)
                    if ($element.offset().top - viewLine > 0) {
                        return false
                    }

                    $('.timeline.nav li[data-index="'+i+'"').addClass('active')
                    active++;
                    let index2= $('.timeline.nav li.active').data('index');
                    let lineInner = $('.line-inner');
                    lineInner.each((i,e) => {
                        let $e = $(e)
                        if($e[0].dataset.index <= index2){
                            $e.addClass('isActive');
                        }
                    });
                    let scrollThings = $('.js-scrollElem');
                    scrollThings.each((i,e) => {
                        let $e = $(e)
                        if($e[0].dataset.index <= index2){
                            $e.addClass('isActive');
                        }
                    });

                    var currElem=$('.js-scrollElem');
                    currElem.each((index, elem) => {
                        if($(elem).hasClass('isActive')){
                            let elemPosTop = $(elem).offset().top;
                            let elemHeight = elem.getBoundingClientRect().height;
                            let scrollTop = $('html').scrollTop();
                            let percentage = 100 * ((scrollTop - elemPosTop) / elemHeight);
                            if (percentage > 0) {
                                $('.line-inner[data-index="' + index + '"]').find('.line-content').css('height', percentage + '%')
                            }else{
                                $('.line-inner[data-index="' + index + '"]').find('.line-content').css('height', '0%');
                            }
                        }
                        else {
                            $('.line-inner[data-index="' + index + '"]').find('.line-content').css('height', '0%');
                        }
                    })
                })
            }
            // else {
            //     $('.line-inner').removeClass('isActive');
            //     $('.js-scrollElem').removeClass('isActive');
            //     active = this.scrollTarget;
            //     let index2 = $('.timeline.nav li.active').data('index');
            //     $('.line-inner[data-index="'+index2+'"]').addClass('isActive');
            //     $('.js-scrollElem[data-index="'+index2+'"]').addClass('isActive');
            // }
            this.timeline.css('top', -1 * active * this.TIMELINE_VALUES.step + this.TIMELINE_VALUES.start + 'px')
            this.liMenuItems.filter('.active').removeClass('active')
            if(active > max) {
                active = max;
            }
            this.liMenuItems.eq(active != -1 ? active : 0).addClass('active');
            this.setActiveButton();
        }).trigger('scroll')
    }

    /**
     *
     * @param {jQuery Ev} e
     */
    _onSectionTimeClick(e){

        let li          = $(e.currentTarget).parent()
        let index       = li.index()
        let milestone   = this.milestones.eq(index)

        $('.section-time').removeClass('isActive');
        milestone.closest('.section-time').addClass('isActive');

        //section time milestone main content container
        $('.js-scrollElem').each((i,e) => {
            let $element = $(e)
            if($element.data('index') <= index){
                $element.addClass('isActive')
            }
        })

        //Scroll to element
        if (! li.hasClass('active') && milestone.length) {
            this.scrollTarget = index
            let scrollTargetTop = milestone.offset().top - 80
            let self = this
            $('html, body').animate({ scrollTop: scrollTargetTop }, {
                duration: 400,
                complete: () => {
                    this.scrollTarget = false;
                    self.setActiveButton();
                }
            })
        }
    }

    /**
     *
     */
    _onWindowScroll() {
        if ($(window).scrollTop() > this.stickyTop) {
            this.timeline.addClass('fixed')
        } else {
            this.timeline.removeClass('fixed')
        }
    }

    /**
     *
     */
    _onWindowResize() {
        this.timeline.removeClass('fixed')
        this.stickyTop = this.timeline.offset().top - this.offsetTop
        $(window).trigger('scroll');
    }

    /**
     *
     * @param {*} e
     */
    changeDecada(e){
        let elem          = $(e.currentTarget);
        let currElem      = $('.timeline.nav li.active');
        let indexCurElem  = currElem.data('index');
        let lastElem      = $('.timeline.nav li:last-child');
        let firstElem     = $('.timeline.nav li:first-child');
        let max           = lastElem.data('index');
        let min           = firstElem.data('index');

        let scrollTarget    = false;

        if(elem.hasClass('js-prev-decada')){
            if(indexCurElem>min){
                elem.addClass('isActive');
                let indexNew=indexCurElem-1;
                if((indexNew-1)<0){
                    elem.removeClass('isActive');
                }
                $('.section-time').removeClass('isActive');

                if(this.milestones.eq(indexNew)){
                    var milestone = this.milestones.eq(indexNew)
                    milestone.closest('.section-time').addClass('isActive');
                    if(indexNew<max){
                        $('.js-next-decada').addClass('isActive');
                    }else{
                        $('.js-next-decada').removeClass('isActive');
                    }
                }

                // Scroll to milestone
                if ( milestone.length) {
                    scrollTarget = indexNew
                    let scrollTargetTop = milestone.offset().top - 80
                    $('html, body').animate({ scrollTop: scrollTargetTop }, {
                        duration: 400,
                        complete: () => {
                            scrollTarget = false
                        }
                    })
                }
            }
            else {
                elem.removeClass('isActive');
                if(max==0){
                    $('.js-next-decada').removeClass('isActive');
                    $('.js-prev-decada').removeClass('isActive');
                }
            }

        }
        else {

            if(indexCurElem<max) {
                let indexNew=indexCurElem + 1;
                $('.section-time').removeClass('isActive'); //all

                if((indexNew + 1)>max){
                    elem.removeClass('isActive');
                }
                $('.section-time').removeClass('isActive');

                if(this.milestones.eq(indexNew)){
                    var milestone = this.milestones.eq(indexNew)
                    milestone.closest('.section-time').addClass('isActive');
                    if(indexNew>min){
                        $('.js-prev-decada').addClass('isActive');
                    }
                    else {
                        $('.js-prev-decada').removeClass('isActive');
                    }
                }

                if ( milestone.length) {
                    scrollTarget = indexNew
                    let scrollTargetTop = milestone.offset().top - 80
                    $('html, body').animate({ scrollTop: scrollTargetTop }, {
                        duration: 400,
                        complete: () => {
                            scrollTarget = false
                        }
                    })
                }

            }
            else {
                elem.removeClass('isActive');
                if(max===indexCurElem && min!=max){
                    elem.removeClass('isActive');
                    $('.js-prev-decada').addClass('isActive');
                }else if(max===indexCurElem && min===max){
                    elem.removeClass('isActive');
                    $('.js-prev-decada').removeClass('isActive');
                }
            }
        }
    }

    setActiveButton(){
        let currElem1=$('.timeline.nav li.active');
        let indexCurElem=currElem1.data('index');
        let lastElem=$('.timeline.nav li:last-child');
        let firstElem=$('.timeline.nav li:first-child');
        let max=lastElem.data('index');
        let min=firstElem.data('index');
        if(min!=max && max>0){
            if(min!=indexCurElem && max!=indexCurElem){
                $('.js-prev-decada').addClass('isActive');
                $('.js-next-decada').addClass('isActive');
            }else if(min===indexCurElem && max!=indexCurElem){
                $('.js-prev-decada').removeClass('isActive');
                $('.js-next-decada').addClass('isActive');
            }else if(min!==indexCurElem && max===indexCurElem){
                $('.js-prev-decada').addClass('isActive');
                $('.js-next-decada').removeClass('isActive');
            }

        }
        else {
            $('.js-prev-decada').removeClass('isActive');
            $('.js-next-decada').removeClass('isActive');
        }
    }

}
