export class Newsletter {

    constructor() {
        fama.utils.debug('Newsletter loaded')
        this.init()

    }

    init() {
        //use all (fama) newsletter
        //Newsletter (iw4 code)
        $('[id^="mailfastsuscription"]').each( (i, item) => {
            $('input[name="mailfastsuscription"]').removeClass('error');

            $(".btn-mailfastsuscription").off().on('click', (e) => {
                let $item = $(e.currentTarget)
                e.preventDefault();
                var isValid = true;
                if($item.closest('.form').find('input[name="mailfastsuscription"]').val() === ''){
                    $item.closest('.form').find('input[name="mailfastsuscription"]').addClass('error');
                    isValid = false;

                }
                else{
                    $item.closest('.form').find('input[name="mailfastsuscription"]').removeClass('error');
                }

                if(!$item.closest('.form').find('.acept').prop('checked')) {
                    isValid = false;
                    $item.closest('.form').find('.acept').addClass('error');
                }
                else{
                    $item.closest('.form').find('.acept').removeClass('error');
                }

                if (isValid) {
                    // fama.utils.debug('aqui2');
                    var options = {
                        email: $item.closest('.form').find('input[name="mailfastsuscription"]').val(),
                        lang: $('html').attr('lang')
                    }

                    $.ajax({
                        url: "/ajax.php?module=newsletter&action=fastsub",
                        data: options,
                        type: "post",
                        async: true,
                        dataType: "json",
                        beforeSend: () => {},
                        success: (r) => {
                            if (r.status == 'ok') {
                                $item.closest('.form').find('.input-box').fadeOut('slow', function () {
                                    $(this).html("<div class='well'>" + $("#mensaje_suscribirse").val() + "</div>").fadeIn('slow');
                                });

                            } else {
                                // fama.utils.debug('aqui5');
                                $item.closest('.form').find('input[name="mailfastsuscription"]').addClass('error');
                            }
                        }
                    });
                }
            });
        })

    }


}
