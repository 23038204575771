import { FamaUtils } from "../../classes/FamaUtils";
import { GalleryBoxFAQ } from "./GalleryBoxFAQ";
// import 'slick-carousel'
export class FAQ {

    utils = FamaUtils.getInstance();
    $items = $('#faqs_2021 .faqs-list li.result')
    _searchTimeout;


    constructor() {
        this.init()
        fama.utils.debug('FAQ Loaded');
    }

    init() {
        //fama.utils.debug('####',jQuery(location).attr('href'));
        let url = $(location).attr('href');
        //fama.utils.debug('######2',url.includes('#'));

        // $('.js-sendHash').on('click', (e) => {this.findInstructionsByHashtag(e)});
        $('.js-sendHash').on('click', (e) => {this.filterInstructionsByHashtag(e)});

        // Scroll to first elem opened if has any opened on start (FAMA)
        if($("#faqs_2021").length > 0){
            const $firstElemOpened = $("#faqs_2021 .faqs-list li.isOpen").first();
            if($firstElemOpened.length > 0) {
                $('html, body').animate({
                    scrollTop: $firstElemOpened.offset().top
                }, 2000);
            }
        }

        if($('.listHashtagsFirst').length>0){
            //localStorage global variante for create list of all instructions
            var instructionsAll=[];
            if($('.js-instructions').length > 0) {
                $('.js-instructions li').each((i, item) => {
                    if($(item).attr('data-id') !== undefined) {
                        instructionsAll.push(parseInt($(item).attr('data-id')));
                    }
                })
            }
            localStorage.setItem('instructionsAll',instructionsAll);
            //fama.utils.debug('instructionsAll1', instructionsAll);
        }

        if(url.includes('#')) { //todo enlaces
            let anchor = url.split('#');
            anchor = anchor[1];
            $(() => {
                // despues del código
                $('.'+anchor).find('.js-faq-search').trigger('click');
            });
        }

        $("#faqs_2021").each( (i, e) => {
            let searchBtn = $(e).find("input[name='buscar']");
            searchBtn.on('keyup', (e) => {
                clearTimeout(this._searchTimeout)
                var $query = $(e.currentTarget).val();
                this._searchTimeout = setTimeout(() => {
                    if ($query.length >= 3) {
                        this.search($query);
                    } else {
                        this.$items.fadeIn('slow');
                        $(".search-result").hide();
                        $(".faqs-list .no-result").hide();
                        
                        //$(".display-mobile").find(".icon").addClass("fa-rotate-180");
                    }
                }, 600);
            });

            $('.js-faq-search').on('click',(e) => {
                this.openFaq($(e.currentTarget));
            });

            $("#faqs_2021 a.util").off("click").on('click',  (e) => {
                var $me = $(e.currentTarget);
                e.preventDefault();
                if ($me.hasClass('util')) {
                    var options = {
                        data: {
                            iw4id: $(this).attr("data-iw4id"),
                            value: 1
                        },
                        onSuccess: (r) => {
                            $me.next().html(r.html);
                            $me.removeClass('util');
                        }
                    }
                    app.utils.ajaxCall("faqs", "vote", options);
                    $('#f' + $(this).attr("data-iw4id") + ' .vote').html('<p><b>' + $("#i18n_faqs_gracias_por_votar").html() + '</b></p>');
                }
            });

        });
    }

    /**
     *
     * @param {*} query
     */
    search(query) {
        this.$items.fadeOut('slow', () => {
            $("#faqs_2021 .faqs-list li.search-result").fadeIn("slow");
        });
        this.$items.each( (i, item) => {
            $(item).removeClass('isActive');
            // fama.utils.debug(' this.$items.each2', $(this).find('a').html());
            var $text = this.utils.removeAccents($(item).find('a').html());
            // fama.utils.debug('$text',$text);
            var $content = this.utils.removeAccents($(item).find('.content.hidden').html());
            // fama.utils.debug('$content',$content);
            if (this.utils.stripos($text, this.utils.removeAccents(query)) || this.utils.stripos($content, this.utils.removeAccents(query))) {
                $(item).fadeIn('slow');
                $(item).addClass('isActive');
            }
        });
        if($('.faqs-list .result.isActive').length < 1){
            $(".faqs-list .no-result").fadeIn();
        }
        if ($("#faqs_2021 .faqs-list:hidden").length < 0) {
            $("#faqs_2021 .faqs-list").fadeIn("slow");
        }
    }

    openFaq($e){
        //console.log('openFaq', $e);
        let $elem=$e.closest('.result').find('.wrap-content');
        // let $listHash=this.getHastags($e.closest('.result'));
        // let $listLink=this.getLinks($e.closest('.result'));

        $elem.toggleClass('isSee');

        if($e.closest('.result').hasClass('isOpen')){
            $e.closest('.result').removeClass('isOpen');
            $('.faqs-list .result').removeClass('isOpen');
            window.history.pushState(null, "", window.location.href);
        }else{
            $('.faqs-list .result').removeClass('isOpen');
            $e.closest('.result').addClass('isOpen');
            if($e.closest('.result').hasClass('isOpen')) {
                window.history.pushState(null, "", '#'+$e.closest('.result').attr('id'));
            }
            //fama.utils.debug(window.location.href);
        }

        //this.putHashButtons($listHash, $listLink, e.closest('.result'));

        // ****************************** SLIDER instructions **************************//
        /*
        if($('.isSee .slider-instructions.only').length > 0){
            $('.slider-instructions.only').not('.slick-initialized').slick({
                slidesToShow: 3,
                centerMode: false,
                autoplay: false,
                //centerMode: true,
                infinite: true,
                initialSlide: 2,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        }
                    },
                ]
            })
        }

        if($('.slider-instructions.only').hasClass('slick-initialized')){
            $('.slider-instructions.only').slick('unslick');
            if($('.slider-instructions.only').length > 0){
                $('.slider-instructions.only').not('.slick-initialized').slick({
                centerMode: false,
                autoplay: false,
                infinite: true,
                initialSlide: 2,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                            centerMode: true,
                            slidesToShow: 1,
                            }
                        },
                    ]
                })
            }
        }
        */
        // ****************************** SLIDER instructions 2 **************************//
        /*
        if($('.slider-instructions.notOnly').length > 0){
            $('.slider-instructions.notOnly').not('.slick-initialized').slick({
                infinite: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                slidesToShow: 1,
                autoplay: false,
                centerMode: true,
                initialSlide: 2,
                dots: false,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        }
                    },
                ]
            })
        }

        if($('.slider-instructions.notOnly').hasClass('slick-initialized')){
            $('.slider-instructions.notOnly').slick('unslick');
            if($('.slider-instructions.notOnly').length > 0){
                $('.slider-instructions.notOnly').not('.slick-initialized').slick({
                    infinite: false,
                    speed: 500,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    focusOnSelect: true,
                    arrows: true,
                    slidesToShow: 1,
                    autoplay: false,
                    centerMode: true,
                    initialSlide: 2,
                    dots: false,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                            centerMode: true,
                            slidesToShow: 1,
                            }
                        },
                    ]
                })
            }
        }
        */
        // ****************************** SLIDER FAQs1 **************************//
        /*
        if($('.isSee .slider-faqs.only').length > 0){
            $('.slider-faqs.only').not('.slick-initialized').slick({
                //centerMode: false,
                autoplay: false,
                centerMode: true,
                slidesToShow: 3,
                infinite: true,
                initialSlide: 2,
                dots: false,
                speed: 500,
                slidesToScroll: 1,
                swipeToSlide: true,
                focusOnSelect: true,
                arrows: true,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        }
                    },
                ]
            })
        }

        if($('.slider-faqs.only').hasClass('slick-initialized')){
            $('.slider-faqs.only').slick('unslick');
            if($('.slider-faqs.only').length > 0){
                $('.slider-faqs.only').not('.slick-initialized').slick({
                    //centerMode: false,
                    autoplay: false,
                    centerMode: true,
                    slidesToShow: 3,
                    infinite: true,
                    initialSlide: 2,
                    dots: false,
                    speed: 500,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    focusOnSelect: true,
                    arrows: true,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                            centerMode: true,
                            slidesToShow: 1,
                            }
                        },
                    ]
                })
            }
        }
        */
        // ****************************** SLIDER FAQs2 **************************//
        /*
        if($('.slider-faqs.notOnly').length > 0){
            $('.slider-faqs.notOnly').not('.slick-initialized').slick({
                    autoplay: false,
                    centerMode: true,
                    slidesToShow: 3,
                    infinite: true,
                    initialSlide: 2,
                    dots: false,
                    speed: 500,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    focusOnSelect: true,
                    arrows: true,
                    variableWidth: true,
                    responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        }
                    },
                ]
            })
        }

        if($('.slider-faqs.notOnly').hasClass('slick-initialized')){
            $('.slider-faqs.notOnly').slick('unslick');
            if($('.slider-faqs.notOnly').length > 0){
                $('.slider-faqs.notOnly').not('.slick-initialized').slick({
                    autoplay: false,
                    centerMode: true,
                    slidesToShow: 3,
                    infinite: true,
                    initialSlide: 2,
                    dots: false,
                    speed: 500,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    focusOnSelect: true,
                    arrows: true,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                            centerMode: true,
                            slidesToShow: 1,
                            }
                        },
                    ]
                })
            }
        }
        */
    }

    /**
     *
     * @param {*} instruction
     * @returns
     */
    getHastags(instruction) {
        //fama.utils.debug('instructionnnn');
        let hashArr = [];

        if (instruction.attr('data-hash')) {
            hashArr = instruction.attr('data-hash').split('|');
            hashArr = hashArr.splice(1, hashArr.length);
        }
        return hashArr;
    }

    /**
     *
     * @param {*} instruction
     * @returns
     */
    getLinks(instruction) {
        let linksArr=[];

        if (instruction.attr('data-links')) {
            linksArr = instruction.attr('data-links').split('|');
            linksArr = linksArr.splice(1, linksArr.length);
        }
        return linksArr;
    }

    /**
     *
     * @param {*} listHashtags
     * @param {*} listLinks
     * @param {*} elem
     * @deprecated
     */
    /*
    putHashButtons(listHashtags, listLinks, elem) {
        let list=$('#faqs_2021').find('.list-hashtags');
        let elemento=elem;
        //fama.utils.debug('elemento', elemento);
        if(elemento.hasClass('isOpen')){
            fama.utils.debug('entroooooo');
            fama.utils.debug('Liiiiiiiiiista', listHashtags.length);
            if(listHashtags.length > 0){
                fama.utils.debug('entroooooo 2');
                list.html('');
                list.addClass('notEmpty');
                let hashtag;
                for(let $i=0; $i<listHashtags.length; $i++ ){
                    //fama.utils.debug('hay hashtaaaaaaaaaaaaaaaaaag',listHashtags[$i]);
                    let hash=listHashtags[$i].replace(' ','_');
                    hashtag=`
                    <div class="hashtag">
                        <div  id=${hash}-`+($i+1)+` class="hashtag-link pointer js-sendHash">
                            #${hash}
                        </div>
                    </div>`;
                    list.append(hashtag);
                }
                hashtag=`
                    <div class="hashtag">
                        <div  id="0" class="hashtag-link pointer js-sendHash">
                            #todos
                        </div>
                    </div>`;
                list.append(hashtag);
                $('.js-sendHash').on('click', (e) => {this.findInstructionsByHashtag(e)});

            }else{
                list.removeClass('notEmpty');
                list.html('');
            }
        }else{
            list.removeClass('notEmpty');
            list.html('');
        }

    }
    */

    /**
     * 
     * @param {str} e 
     * filter of issue by hash
     */
    filterInstructionsByHashtag(e) {
        console.log("Ejecutandose")
        let $elem = $(e.currentTarget).closest('.hashtag');
        let $clickedHashtag = $elem.find('.hashtag-link').attr('id')
        $('.js-sendHash').closest('.hashtag').removeClass('is-active');
        $elem.addClass('is-active');
        let $items = $('.js-instructions li.result')
        let bShow = false

        if($clickedHashtag == 'todos') {
            $items.fadeIn()
        }
        else {
            $items.show()
            $items.each((i, item)=> {
                bShow = false
                let $item = $(item)
                let hashtags = ($item.data("hash") ?? "").replace(/^\||\|$/g, '').split('|')
                hashtags.some(hashtag => {
                    if(hashtag == $clickedHashtag) {
                        bShow = true
                    }
                    return bShow
                })
                if (!bShow) $item.hide()
            });

        }
    }

    /**
     *old function with ajax
     */
    //not use
    /*
    findInstructionsByHashtag(e) {
        let $elem = $(e.currentTarget).closest('.hashtag');
        $('.js-sendHash').closest('.hashtag').removeClass('is-active');
        $elem.addClass('is-active');

        var instructionsValues=[]
        if($('.js-instructions').hasClass('listHashtagsFirst')){
            $('.js-instructions').removeClass('listHashtagsFirst');
        }

        if(localStorage.getItem('instructionsAll')) {
            instructionsValues=localStorage.getItem('instructionsAll').split(',');
        }

        let data = {
            hash: $(e.currentTarget).data('hashid'),
            instructions: instructionsValues,
            lang: document.documentElement.lang,
            page: window.location.href,
        }
        console.log(data);
        $.ajax({
            type: 'POST',
            url: '/api/search-instructions-by-hashtags',
            data: data,
            beforeSend: () => {},
        })
        .done ((response) => {
            console.log('responseeeeeeeeeeeeee',response);

            if(response.status=='OK' && !response.message){
                let list=$('#faqs_2021 .faqs-list.js-instructions');
                list.html('');
                list.addClass('listHashtags');

                let content='';
                let title='';
                let id_title='';
                let id='';
                let video='';
                let listHashArr=[];
                let listHashJoin=[];
                let listHash=[];
                let listLinks=[];
                let imagesArr=[];
                let images=[];

                for(let $i=0; $i<response.instructions.length; $i++ ){
                    //fama.utils.debug(response.instructions[$i]);
                    let isOpen='';
                    let num='';
                    
                    if($i<=9){
                        num='0'+($i+1);
                    }else{
                        num=($i+1);
                    }
                    content=response.instructions[$i].content;
                    title=response.instructions[$i].title;
                    id_title=response.instructions[$i].id_title;
                    id=response.instructions[$i].id;

                    if(response.instructions[$i].video && response.instructions[$i].video!=''){
                        video=response.instructions[$i].video;
                    }
                    if(response.instructions[$i].hashtags.length>0){
                        listHashArr=response.instructions[$i].hashtags;
                        //fama.utils.debug('response.instructions[$i].hashtags.length', response.instructions[$i].hashtags.length);
                        for(let $y=0; $y<listHashArr.length; $y++){
                            if($y==0){
                                listHashArr[0].name='|'+listHashArr[0].name;;
                            }
                            // fama.utils.debug('listHashArr22222', listHashArr[$y].name);
                            listHash.push(listHashArr[$y].name);
                        }
                        listHashJoin=listHash.join('|');
                        //fama.utils.debug('listHashJoin', listHashJoin);

                    }
                    if(response.instructions[$i].images.length>0){

                        imagesArr=response.instructions[$i].images;
                        if(imagesArr.length>0){
                            for(let $x=0; $x<imagesArr.length; $x++){
                                images.push(imagesArr[$x].url);
                                //fama.utils.debug('dddddd',imagesArr[$x].url);
                            }
                            //fama.utils.debug(images);
                        }
                    }

                    let instruction=
                    `<li class="result res-instruction-`+id+` `+ id_title +`" data-id="`+id+`" id="`+id_title+`" data-hash="`+listHashJoin+`" data-links="`+listLinks+`">
                        <span class="num">`+num+`</span>
                        <a href="" data-id="f`+id+`" class="pointer faq_link">
                            `+title+`
                        </a>
                        <p data-id="f`+id+`" class="pointer faq_link js-faq-search">
                            `+title+`
                        </p>
                        <div class="wrap-content wrap-content-res">
                            <div class="content text generic">`+content+`</div>
                        </div>
                    <li>`;
                    list.append(instruction);
                    let wrapMedia='<div class="wrap-media wrap-media-res"></div>';
                    $('.res-instruction-'+ id).find('.wrap-content-res').append(wrapMedia);

                    if(video!=''){
                        var iframe='<iframe class="video js-vimeo" width="100%" height="250px" src="'+video+'"  title=""  frameborder="0" allowfullscreen></iframe>';
                        $('.res-instruction-'+ id).find('.wrap-media-res').append(iframe);
                    }

                    if(images.length>0){
                        //fama.utils.debug('images.length',images.length);
                        var slider='<div class="aux-slider"><div class="pasadorPost faq swiper-container pasadorPost-res" style="width:100%;"><div class="swiper-wrapper swiper-wrapper-res"></div><div class="control control-prev fs1" data-icon="4"></div><div class="control control-next fs1" data-icon="5"></div></div></div>';
                        $('.res-instruction-'+ id).find('.wrap-media-res').append(slider);

                        for(let $z=0; $z<images.length; $z++) {
                            if(images[$z]!=""){
                                var swiperSlide='<span class="swiper-slide"><div class="wrap-item"><img src="'+images[$z]+'" class="img-responsive" alt="" title="" data-source="'+images[$z]+'"> </div> </span>';

                                $('.res-instruction-'+ id).find('.pasadorPost .swiper-wrapper-res').append(swiperSlide);
                            }
                        }

                        let mygallerybox='<div class="mygallerybox faq mygallerybox-res"></div>';
                        let swiperContainer='<div class="swiper-container swiper-id"></div>';
                        let btnClose='<i class="btn-close pointer"></i>';
                        let swiperWrapper='<div class="swiper-wrapper"></div>';
                        let swiperButtons='<div class="swiper-button-prev"></div><div class="swiper-button-next"></div>';

                        $('.res-instruction-'+ id).find('.wrap-media').append(mygallerybox);
                        $('.res-instruction-'+ id).find('.mygallerybox.faq').append(swiperContainer);
                        $('.res-instruction-'+ id).find('.mygallerybox.faq').append(btnClose);
                        $('.res-instruction-'+ id).find('.mygallerybox.faq .swiper-container').append(swiperWrapper);
                        $('.res-instruction-'+ id).find('.mygallerybox.faq .swiper-container').append(swiperButtons);


                        for(let $z=0; $z<images.length; $z++) {
                            if(images[$z]!=""){
                                var swiperSlideGal=
                                `<div class="swiper-slide">
                                    <img src="`+images[$z]+`" class="slide-img swiper-lazy" alt="" title="" data-src="`+images[$z]+`">
                                    <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                </div>`;
                                $('.res-instruction-'+ id).find('.mygallerybox.faq .swiper-wrapper').append(swiperSlideGal);
                            }
                        }
                    }

                    listHashArr.length=0;
                    listHash.length=0;
                    imagesArr.length=0;
                    images.length=0;
                    video='';

                }

                $('.js-faq-search').on('click', (e) => {
                    //fama.utils.debug('openFaqqqqqqqq', $(e.currentTarget), $(this));
                    this.openFaq($(e.currentTarget));
                });
            } else if(response.status=='OK' && response.message) {
                window.open(response.current_page, "_self");
            }
            setTimeout(() => {
                var $swiperContainer = $('.mygallerybox-res.faq');
                if ($swiperContainer.length > 0) {
                    $swiperContainer.each((i, Slider) => {
                        // GalleryBoxFAQ.init($(Slider));
                    });
                }
            },200)

        });
        
    }
    */
}
